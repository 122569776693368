import moveFromIndexToIndex from "../../../../../../utils/moveFromIndexToIndex";

export default (args: {updateFetchedData: any, fetchedData: any}) => (columnId: string, direction: number) => {
  const index: number = [...args.fetchedData.missionProfile.customKanbanView].map((item: any) => (item._id)).indexOf(columnId);
  const newColumns2 = moveFromIndexToIndex([...args.fetchedData.missionProfile.customKanbanView], index, index + direction)
  args.updateFetchedData()({
    target: {
      type: "custom", name: "missionProfile", value: {
        ...args.fetchedData.missionProfile,
        customKanbanView: newColumns2,
      }
    }
  });
  return newColumns2;
}