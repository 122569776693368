import React from "react";

export {HeaderRecruiterTitle};

const HeaderRecruiterTitle = (props: {
  businessName: string,
  numberOfOpenedPositions: number,
}) => {
  
  return(<div className={"page-layout__header__main-content--recruiter"}>

    <div className={"page-layout__header__main-content--recruiter__title"}>
      {props.businessName}
    </div>
  
    <div className={"page-layout__header__main-content--recruiter__subtitle"}>
      {props.numberOfOpenedPositions} recrutement{props.numberOfOpenedPositions !== 1 && "s"} en cours
    </div>
  
    <div className={"page-layout__header__main-content--recruiter__big-title"}>
      {props.businessName}
    </div>
    
  </div>);
}