import AppContext, {IAppContext, IAppV2Context} from "../../../../componentsApp/context/AppContext";
import React, {useContext} from "react";
import WindowWrapper from "../../../layouts/WindowLayout";
import Spaces from "../../Spaces";
import {useWizard} from "use-wizard";
import {useForm} from "use-formidable";
import {notification} from "../../../../../utils/notification";
import axios from "axios";

export default function(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [step, wizard] = useWizard(["entry", "warningDeletion", "pending"]);
  
  const [pool, updateForm, formidable] = useForm({...appContextV2.appPanels.window.windowOptions.data?.item});
  
  const deletePool = async() => {
    try {
      
      wizard.goToStep('pending');
      
      const fetched: any = await axios.put(`${process.env.REACT_APP_API_URL}/pool/m/delete/pool`, {
        poolId: pool._id,
      }, {withCredentials: true});
      
      if(fetched.data.status !== "ok") {
        wizard.goToStep('entry');
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      
      // Update front
      appContextV2.appPanels.window.windowOptions.data?.deleteFront(pool._id);
      
      notification(appContextV2.dispatchPanels, "Mise à jour avec succès", "success");
      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      
    } catch (error) {
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  const updatePrivacy = async() => {
    try {
      
      wizard.goToStep('pending');
      
      const fetched: any = await axios.put(`${process.env.REACT_APP_API_URL}/pool/m/update/pool`, {
        poolId: pool._id,
        private: !pool.private,
      }, {withCredentials: true});
      
      if(fetched.data.status !== "ok") {
        wizard.goToStep('entry');
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      
      updateForm()({target: {type: "custom", name: "private", value: !pool.private}})
      
      // Update front
      appContextV2.appPanels.window.windowOptions.data?.updatePrivacyFront(pool._id, !pool.private);
      
      notification(appContextV2.dispatchPanels, "Mise à jour avec succès", "success");
      wizard.goToStep('entry');
  
    } catch (error) {
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  const updatePool = async() => {
    try {
      
      wizard.goToStep('pending');
      
      const fetched: any = await axios.put(`${process.env.REACT_APP_API_URL}/pool/m/update/pool`, {
        poolId: pool._id,
        name: pool.name,
        color: pool.color,
      }, {withCredentials: true});
      
      if(fetched.data.status !== "ok") {
        wizard.goToStep('entry');
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      
      // Update front
      
      appContextV2.appPanels.window.windowOptions.data?.updateFront(pool._id, pool.name, pool.color);
      
      notification(appContextV2.dispatchPanels, "Mise à jour avec succès", "success");
      wizard.goToStep('entry');
  
    } catch (error) {
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  if(step === "pending") {
    return (<WindowWrapper windowTitle={""}>
    </WindowWrapper>);
  }
  
  if(step === "warningDeletion") {
    return (<WindowWrapper windowTitle={""} status={"ok"}>
      <div className={"text--center"}>
        Es-tu sûr(e) de vouloir supprimer la communauté '{pool.name}' ? Les candidats ne seront pas affectés si ils sont en également dans une autre communauté.
      </div>
      <Spaces n={1}/>
      <div className={"text--center"}>
        <button className={"btn-main"} onClick={() => {
          wizard.goToStep("entry");
        }}>
          Annuler
        </button>
        <button className={"btn-main"} onClick={() => deletePool()}>
          Supprimer
        </button>
      </div>
    </WindowWrapper>);
  }
  
  return (<WindowWrapper windowTitle={""} status={"ok"}>
    <div className={"text--center"}>
      Nom de la communauté: <br/>
      <input type={"text"} name={"name"} value={pool.name} onChange={updateForm({maximumLength: 25})}/>
    </div>
    
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      <button onClick={() => {
        updateForm()({target: {type: "custom", name: "color", value: 'green'}})
      }} className={`color-pool color-pool--green ${(pool.color === "" || pool.color === "green") ? "color-pool--selected" : ""}`}>
      </button>
      <button onClick={() => {
        updateForm()({target: {type: "custom", name: "color", value: 'blue'}})
      }} className={`color-pool color-pool--blue ${(pool.color === "blue") ? "color-pool--selected" : ""}`}>
      </button>
      <button onClick={() => {
        updateForm()({target: {type: "custom", name: "color", value: 'yellow'}})
      }} className={`color-pool color-pool--yellow ${(pool.color === "yellow") ? "color-pool--selected" : ""}`}>
      </button>
      <button onClick={() => {
        updateForm()({target: {type: "custom", name: "color", value: 'orange'}})
      }} className={`color-pool color-pool--orange ${(pool.color === "orange") ? "color-pool--selected" : ""}`}>
      </button>
      <button onClick={() => {
        updateForm()({target: {type: "custom", name: "color", value: 'pink'}})
      }} className={`color-pool color-pool--pink ${(pool.color === "pink") ? "color-pool--selected" : ""}`}>
      </button>
    </div>
    
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      <button className={"btn-main"} onClick={() => {
        wizard.goToStep("warningDeletion");
      }} title={"Supprimer la communauté"}>
        Supprimer
      </button>
      {
        pool.IAmOwner && <button className={"btn-main"} onClick={() => updatePrivacy()} title={"Rends la communauté privée, seul toi pourra y accéder"}>
          {!pool.private && "Privée"}
          {pool.private && "Publique"}
        </button>
      }
      <button className={"btn-main"} title={"Enregistre les modifications"} onClick={() => updatePool()}>
        Enregistrer
      </button>
    </div>
  </WindowWrapper>);
}