import React, {useContext} from "react";
import {ButtonBigPlusV2} from "../../../sharedComponents/components/Buttons/ButtonBigPlus";
import Spaces from "../../../sharedComponents/components/Spaces";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const addQuestion = (formId: string, question: any) => {
    if(formId === 'form') {
      const newForm1 = [...props.form.form, question];
      props.setForm()({target: {type: "custom", name: "form", value: newForm1}});
    }
    if(formId === 'form2') {
      const newForm2 = [...props.form.form2, question];
      props.setForm()({target: {type: "custom", name: "form2", value: newForm2}});
    }
  }
  
  return (<div className={"space-flex--center-column"}>
    <Spaces n={2}/>
    <ButtonBigPlusV2 cb={() => appContextV2.dispatchPanels({
      type: 'OPEN_EDITOR_PANEL', value: {
        type: 'default',
        data: {
          addQuestion: addQuestion,
          formId: props.id,
        }
      }
    })} title={"Ajoute une question"}/>
  </div>);
}