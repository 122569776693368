import getGlobalMark from "./helpers/getGlobalMark";

export default (args: {
  fetchedData: any,
  filterByWords: any,
  filterByMark: any,
  setDisplayedShortlist: Function,
}) => {

  const shortlistCopy = [...args.fetchedData.shortlist];

  /** Defines which filters are active */
  const isFilterByWordsActive: boolean = !!args.filterByWords;
  const isFilterByMarkActive: boolean = !!args.filterByMark;

  /** Defines results for each filter */
  let idFilteredByWords: string[] = [];
  let idFilteredByMark: string[] = [];

  /** Defines the result */
  let resultShortlist: any = [];

  if (!isFilterByWordsActive && !idFilteredByMark) {
    resultShortlist = shortlistCopy;
  } else {

    /** Get IDs of result for words filter */
    (isFilterByWordsActive && (idFilteredByWords = shortlistCopy
        .filter((item: any) => (
          item.firstName?.toLowerCase().includes(args.filterByWords.toLowerCase()) ||
          item.lastName?.toLowerCase().includes(args.filterByWords.toLowerCase())
        ))
        .map((item: any) => (item._id))
    ));

    /** Get IDs of result for words filter */
    if (isFilterByMarkActive) {
      switch (args.filterByMark) {

        case '100':
          idFilteredByMark = shortlistCopy
            .filter((item: any) => (getGlobalMark(item.markSkills, item.markForm) <= 100 && getGlobalMark(item.markSkills, item.markForm) >= 90 && !item.imported))
            .map((item: any) => (item._id));
          break;

        case '90':
          idFilteredByMark = shortlistCopy
            .filter((item: any) => (getGlobalMark(item.markSkills, item.markForm) < 90 && getGlobalMark(item.markSkills, item.markForm) >= 70 && !item.imported))
            .map((item: any) => (item._id));
          break;

        case '70':
          idFilteredByMark = shortlistCopy
            .filter((item: any) => (getGlobalMark(item.markSkills, item.markForm) < 70 && getGlobalMark(item.markSkills, item.markForm) >= 50 && !item.imported))
            .map((item: any) => (item._id));
          break;

        case '50':
          idFilteredByMark = shortlistCopy
            .filter((item: any) => (getGlobalMark(item.markSkills, item.markForm) < 50 && !item.imported))
            .map((item: any) => (item._id));
          break;

        case 'blocked':
          idFilteredByMark = shortlistCopy
            .filter((item: any) => (item.blockedByAlgorithm || item.status === "blocked"))
            .map((item: any) => (item._id));
          break;

        case 'imported':
          idFilteredByMark = shortlistCopy
            .filter((item: any) => item.imported)
            .map((item: any) => (item._id));
          break;
      }
    }

    /** Add IDs to the result  */
    // For each ID, add it only if it matches active filters
    for (let candidacy of shortlistCopy) {
      if (isFilterByWordsActive && !idFilteredByWords.includes(candidacy._id)) {
        continue;
      }
      if (isFilterByMarkActive && !idFilteredByMark.includes(candidacy._id)) {
        continue;
      }
      resultShortlist.push(candidacy);
    }
  }

  const sortedShortlist: any = [...resultShortlist].sort((a: any, b: any) => (getGlobalMark(b.markSkills, b.markForm) - getGlobalMark(a.markSkills, a.markForm)));

  let organizedShortlist = [
    ...sortedShortlist.filter((item: any) => (item.status !== "withdrawed" && item.status !== "rejected" && !item.blockedByAlgorithm)),
    ...sortedShortlist.filter((item: any) => (item.status !== "withdrawed" && item.status !== "rejected" && !!item.blockedByAlgorithm)),
    ...sortedShortlist.filter((item: any) => (item.status === "withdrawed")),
    ...sortedShortlist.filter((item: any) => (item.status === "rejected")),
  ];

  args.setDisplayedShortlist(organizedShortlist);

}