import React from "react";


export default function RatingComponent(props: {updateFunction: Function, name: string, grade: number}) {
  
  return (<>
    <div >
      <div className={"rating-component"}>
        <button className={props.grade===1 ? "rating-component--white rating-component--selected":"rating-component--white"} onClick={()=>props.updateFunction(props.name, 1)}>1</button>
        <button className={props.grade===2 ? "rating-component--white rating-component--selected":"rating-component--white"} onClick={()=>props.updateFunction(props.name, 2)}>2</button>
        <button className={props.grade===3 ? "rating-component--white rating-component--selected":"rating-component--white"} onClick={()=>props.updateFunction(props.name, 3)}>3</button>
        <button className={props.grade===4 ? "rating-component--white rating-component--selected":"rating-component--white"} onClick={()=>props.updateFunction(props.name, 4)}>4</button>
        <button className={props.grade===5 ? "rating-component--white rating-component--selected":"rating-component--white"} onClick={()=>props.updateFunction(props.name, 5)}>5</button>
      </div>
    </div>
  
  </>);
}