
export default {
  title: '',
  description: '',
  status: '',
  document: '',
  positionWanted: "",
  positionWantedCode: "",
  numberOfPositionsWanted: "1",
  contract: "",
  yearsOfExperience: 0,
  asSoonAsPossible: false,
  duration: "",
  permanent: false,
  from: "",
  to: "",
  renewalPossibility: false,
  salaryBy: 'monthly',
  salaryCurrency: 'euros',
  salaryMin: "",
  salaryMax: "",
  salaryVariable: 0,
  isJobFullTime: true,
  skills: [],
  advantages: [],
  remote: "no",
  location: {
    number: "",
    street: "",
    city: "",
    state: "",
    postalcode: "",
    country: "France",
    sup: "",
  },
  withoutCv: false,
  cvMandatory: false,
  refsMandatory: false,
  form: [],
  form2: [],
  linkedToPools: [],
}


  // documentUrl: '',
  // token: '',
  // rank: '',
