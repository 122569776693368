import React from "react";

export {RecruiterParametersPersonnal}

const RecruiterParametersPersonnal = (props: any) => {
  
  return(<div className={"container white-container"}>
    <h1 className={"profile-edit__title"}>Paramètres personnels</h1>
    Cette section n,'est pas encore disponible. N'hésitez pas à solliciter l'équipe pour nous décrire vos besoins.
  </div>);
}