export default (args: {updateFetchedData: Function, fetchedData: any}) => (candidacyId: string, toColumnId: string) => {
  const indexOfCandidacy: number = [...args.fetchedData.shortlist].map((item: any) => (item._id)).indexOf(candidacyId);
  let newShortlist = [...args.fetchedData.shortlist];
  newShortlist[indexOfCandidacy] = {
    ...newShortlist[indexOfCandidacy],
    kanbanColumn: toColumnId,
    kanbanColumnHistory: [...newShortlist[indexOfCandidacy].kanbanColumnHistory, toColumnId]
  }
  args.updateFetchedData()({
    target: {
      type: "custom", name: "shortlist", value: newShortlist,
    }
  });
}