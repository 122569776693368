import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";
import SubPageLayout from "../../../../layouts/SubPageLayout";
import Spaces from "../../../../components/Spaces";
import LoadingStyle2 from "../../../../components/Loading/LoadingStyle2";
import axios from "axios";
import {notification} from "../../../../../../utils/notification";
import BPAV3SuccessResult from "./componentsBPAV3Success/BPAV3SuccessResult";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [sending, setSending] = useState(false);
  
  const subscribe = async() => {
    setSending(true);
    const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/ent/u/new/user/applying`, {
      email: props.answer.email,
      areTermsAccepted: true,
      isPolicyAccepted: true,
    }, {
      withCredentials: true,
      timeout: 70000,
    });
    
    if(fetched.data.status !== "ok") {
      setSending(false);
      return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
    
    setSending(false);
    notification(appContextV2.dispatchPanels, "Inscription réussie", "success");
    props.wizard.goToStep('success');
  }
  
  return (<SubPageLayout status={"ok"} subPage={props.subPage}>
    
    <Spaces n={2}/>
    
    <div className={"apply-V2__title text--new-blue"}>
      Candidature enregistrée avec succès !
    </div>
    
    <Spaces n={1}/>
    
    {/*{!props.mission?.hideResult && <><BPAV3SuccessResult globalMark={67}/><Spaces n={1}/></>}*/}
    {!props.mission?.hideResult && <><BPAV3SuccessResult globalMark={props.candidacy.globalMark}/><Spaces n={1}/></>}
    
    <section className={"container text--center"}>
      
      <article className={"white-container white-container--grey"}>
        Vous pouvez maintenant fermer cette fenêtre.
        <Spaces n={0}/>
        Pour recevoir des offres similaires, n'hésitez pas à vous inscrire sur la plateforme.
      </article>
    
    </section>
    
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      <button className={"btn-secondary"} onClick={() => subscribe()}>
        Je m'inscris pour recevoir d'autres offres
      </button>
    </div>
    
    <div className={"text--center"}>
      {sending && <>
        <br/>
        <LoadingStyle2/>
      </>}
    </div>
  
  </SubPageLayout>);
}