export default (args: {updateFetchedData: Function, fetchedData: any}) => (id: string) => {
  const newColumns = [...args.fetchedData.missionProfile.customKanbanView, {
    activateForm: false,
    activateMail: false,
    activatePool: false,
    description: "",
    mailContent: "",
    mailDelay: 0,
    mailSubject: "",
    name: "Nouvelle colonne",
    poolId: "",
    _id: id,
  }];
  args.updateFetchedData()({
    target: {
      type: "custom", name: "missionProfile", value: {
        ...args.fetchedData.missionProfile,
        customKanbanView: newColumns,
      }
    }
  });
}