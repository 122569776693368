import React from "react";

export default function EmptyItemForm(props: any) {
  
  return (
    <div className={"editor-v2__section__question--empty text--center"}
         onDragOver={(e) => {e.preventDefault();}}
         onDrop={() => {props.dropOnEmpty(props.id)}}>
      
      <div className={"text--grey"}>Ajoute une question ou glisse-en une ici pour l'ajouter.</div>
    </div>
  );
}