import {initialSession} from "../state/initialSession";
import {IAppV2Session} from "../state/IAppV2";

export {sessionReducer};

export type {ISetSessionAction}

interface ISetSessionAction {
  type: string;
  value: any;
}

const sessionReducer = (state: IAppV2Session, action: ISetSessionAction): IAppV2Session => {
  
  switch(action.type) {

    case 'SET_SESSION':
      return ({
        ...state,
        app: {
          ...state.app,
          mode: action.value?.data?.mode,
          appStatus: "ok",
          space: action.value?.data?.space,
          allowSignup: action.value?.data?.allowSignup,
        },
        session: {
          ...state.session,
          firstName: action.value?.data?.firstName,
          lastName: action.value?.data?.lastName,
          trigram: action.value?.data?.trigram,
          userAvatar: action.value?.data?.userAvatar,
          ban: action.value?.data?.ban,
          profileLink: {
            ...state.session.profileLink,
            name: action.value?.data?.profileLink?.name,
            number: action.value?.data?.profileLink?.number,
          },
          userStatus: action.value?.data?.userStatus,
          userRole: action.value?.data?.userRole,
          isEmailVerified: action.value?.data?.isEmailVerified,
          isListeningToMarket: action.value?.data?.isListeningToMarket,
        },
        sessionBusiness: {
          ...state.sessionBusiness,
          businessName: action.value?.data?.businessName,
          openedMissions: action.value?.data?.openedMissions,
          creditForSms: action.value?.data?.creditForSms,
          isRecruitingAgency: action.value?.data?.isRecruitingAgency,
          profileLink: {
            ...state.session.profileLink,
            name: action.value?.data?.profileLink?.name,
            number: action.value?.data?.profileLink?.number,
          },
          plan: action.value?.data?.plan,
          businessAvatar: action.value?.data?.businessAvatar,
        }
      });

    case 'INITIALIZE_SESSION':
      return ({
        ...state,
        ...initialSession,
        app: {
          ...state.app,
          mode: state.app.mode,
          appStatus: 'pending',
          language: state.app.language,
        },
        session: {
          ...state.session,
          userStatus: "",
        },
      });
      
    case "SET_SESSION_ERROR":
      return({
        ...state,
        app: {
          ...state.app,
          appStatus: "error",
        }
      });
  
    case 'SET_LANGUAGE':
      const languages = ["FR", "EN"];
      if(!languages.includes(action.value as string)) {
        throw new Error('Language is not valid');
      }
      return ({
        ...state,
        app: {
          ...state.app,
          language: action.value as "FR" | "EN",
        }
      });
      
    case 'UPDATE_MISSIONS_NUMBER':
      return {
        ...state,
        sessionBusiness: {
          ...state.sessionBusiness,
          openedMissions: state.sessionBusiness.openedMissions + action.value,
        }
      };
      
    case 'UPDATE_SESSION':
      return({
        ...state,
        session: {
          ...state.session,
          [action.value.key]: action.value.value,
        }
      });
  
    case 'UPDATE_APP':
      return({
        ...state,
        app: {
          ...state.app,
          [action.value.key]: action.value.value,
        }
      });
  
    case 'UPDATE_SESSION_BUSINESS':
      return({
        ...state,
        sessionBusiness: {
          ...state.sessionBusiness,
          [action.value.key]: action.value.value,
        }
      });
      
    case 'USE_SMS_CREDIT':
      return({
        ...state,
        sessionBusiness: {
          ...state?.sessionBusiness,
          creditForSms: (state?.sessionBusiness?.creditForSms || 0) - action.value
        }
      });

    default:
      throw new Error(`Type ${action.type} is not defined in sessionReducer`);
  }

}