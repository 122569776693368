import React, {useContext} from "react";
import getContract from "../../../utils/getContract";
import getExperience from "../../../utils/getExperience";
import getTimeElapsed from "../../../utils/getTimeElapsed";
import {capitalizeSentence} from "../../../utils/converters";
import getMissionStatus from "../../../utils/getMissionStatus";
import getMissionStatusColor from "../../../utils/getMissionStatusColor";
import {Link} from "react-router-dom";
import axios from "axios";
import {notification} from "../../../utils/notification";
import {devLog} from "../../../utils/devLog/devLog";
import AppContext, {IAppV2Context} from "../../componentsApp/context/AppContext";
import AvatarsBloc from "./AvatarsBloc";

export {RecruiterMissionsMissionV2};

const RecruiterMissionsMissionV2 = (props: {
  item: any
  isResearchActive: boolean,
  pinFront: any,
  deleteFront: any,
  updateFront: any,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const pin = async() => {
    try {
      // Update front
      props.pinFront(props.item.missionId, !props.item.pinned);
      // Update back
      const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/ent/m/pin`, {
        missionId: props.item.missionId,
        pinned: !props.item.pinned,
      }, {withCredentials: true});
      if(fetched.data.status !== "ok") {
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      notification(appContextV2.dispatchPanels, "Registre des épingles mis à jour", "success");
    } catch (error) {
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      devLog(error);
    }
  }
  
  return (<article className={`mission--v2 ${props.isResearchActive && "mission--v2--active-research"}`}>
    
    <div className={'mission--V2--left hide-on-smartphones'}>
      <div className={"mission--V2__candidacies__number"}>
        {props.item.numberOfCandidacies}
      </div>
      <div className={"mission--V2__candidacies__title hide-on-smartphones"}>
        Candidats
      </div>
    </div>
    
    <div className={'mission--V2--right'}>
      <div className={"mission--V2--right__line"}>
        <div className={"mission--V2__title"}>
          {capitalizeSentence(props.item.positionWanted)}
        </div>
        <div className={"mission--V2__buttons"}>
          <button className={"btn--hollow"} onClick={() => {
            appContextV2.dispatchPanels({
              type: "OPEN_MENU_FROM_BOTTOM", value: {
                type: "missionStatus",
                data: {
                  missionId: props.item.missionId,
                  status: props.item.status,
                  updateFront: props.updateFront,
                  deleteFront: props.deleteFront,
                }
              }
            })
          }}>
            <div className={`mission--V2__status ${getMissionStatusColor(props.item.status)}`}>
              <img className={"mission--V2__status__arrow"} src={"https://static.myshortlist.co/btn-arrow-list.svg"} />{getMissionStatus(props.item.status)}
            </div>
          </button>
          <button className={`pool--v2__buttons__pinned ${props.item.pinned ? "pool--v2__buttons__pinned--active" : ""}`} onClick={() => pin()}>
            <img src={"https://static.myshortlist.co/btn-pin.svg"}/>
          </button>
        </div>
      </div>
      <div className={"mission--V2__comment"}>
        {getContract(props.item.contract)}, {getExperience(props.item.yearsOfExperience)}, <span className={"text--new-blue"}>Créé
        depuis {getTimeElapsed((props.item.dateOfCreation), 'FR', false)}</span>
      </div>
      <div className={"mission--V2--right__line"}>
        <div>
          <AvatarsBloc avatars={props.item.shortlistAvatars} />
        </div>
        <div className={"mission--V2__buttons"}>
          <Link to={`/business/mission/${props.item.missionId}/editor`}>
            <button className={"mission--V2__buttons__parameters"}>
              <img src={"https://static.myshortlist.co/btn-parameters.svg"}/>
            </button>
          </Link>
          <button className={"mission--V2__buttons__parameters"} onClick={() => {
            appContextV2.dispatchPanels({
              type: "OPEN_WINDOW", value: {
                type: "magicMissionLink",
                data: props.item,
              }
            })
          }}>
            <img src={"https://static.myshortlist.co/btn-linkink.svg"}/>
          </button>
          <Link to={`/business/mission/${props.item.missionId}`}>
            <button className={"mission--V2__buttons__main"}>
              Kanban
            </button>
          </Link>
        </div>
      </div>
    </div>
  
  </article>)
}