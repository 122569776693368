import React, {useContext, useState} from "react";
import CloseButton from "../../../Buttons/CloseButton";
import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";
import Spaces from "../../../Spaces";
import {capitalizeMulti} from "../../../../../../utils/converters";
import {notification} from "../../../../../../utils/notification";
import LinkedInIcon from "../../../Buttons/LinkedinIcon";
import {devLog} from "../../../../../../utils/devLog/devLog";
import axios from "axios";
import {format} from "date-fns";
import {isHighlighted} from "./dependencies/isHighlighted";
import getFormatedPhone from "../../../../../../utils/getFormatedPhone";
import {Link} from "react-router-dom";

export default (props: {
  inherited: any,
  windowStatus: any,
  updateCandidateFrontInWindow: any,
  toggleAvailability: any,
  candidate: any
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const [updatingProfile, setUpdatingProfile] = useState(false);
  
  const firstName: string = props.candidate?.candidate?.firstName ? props.candidate?.candidate?.firstName : props.inherited.basicData.firstName;
  const lastName: string = props.candidate?.candidate?.lastName ? props.candidate?.candidate?.lastName : props.inherited.basicData.lastName;
  
  return (<>
    
    <div className={"text--right"}>
      <CloseButton eventOnClick={() => appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null})}/>
    </div>
    
    <div className={"text--center"}>
      <div className={"window__candidate__avatar"}>
        {props.inherited.basicData?.avatarUrl && <img src={props.inherited.basicData?.avatarUrl}/>}
        {!props.inherited.basicData?.avatarUrl && <>{props.inherited.basicData.firstName ? (props.inherited.basicData.firstName || "N")[0] : "N"}{props.inherited.basicData.lastName ? (props.inherited.basicData.lastName[0] || "N") : "N"}</>}
      </div>
    </div>
    
    <Spaces n={0}/>
    
    <div className={`text--center window__candidate__name ${(isHighlighted(props.inherited.keywords, props.inherited.basicData.firstName) || isHighlighted(props.inherited.keywords, props.inherited.basicData.lastName) ? "profile--highlighted" : "" )}`}>
      {capitalizeMulti(firstName)} {capitalizeMulti(lastName)}
    </div>
    
    <div className={"space-flex--left"}>
  
    {
      props.candidate?.candidate?.linkedin && <a href={props.candidate?.candidate?.linkedin} target={"_blank"}><button className={"btn--hollow"} onClick={()=>{
        // navigator.clipboard.writeText(props.candidate?.candidate?.linkedin);
        // notification(appContextV2.dispatchPanels, "Lien copié !", "success");
      }}>
        <LinkedInIcon/>
      </button></a>
    }
    {
      !props.candidate?.candidate?.linkedin && <button className={"btn--hollow hollow"} disabled={true}>
        <LinkedInIcon/>
      </button>
    }
    <select className={"window__candidate__mark"} value={props.candidate?.candidate?.gradeByTeam} onChange={async(e) => {
      try {
        // update front window
        props.updateCandidateFrontInWindow({gradeByTeam: e.target.value});
        // update front pool
        props.inherited.frontUpdatePool.updateCandidatesBulk([props.inherited.candidateId], {gradeByTeam: e.target.value});
        // update front mission
        const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/pool/cm/update/grade`, {
          candidateId: props.inherited.candidateId,
          gradeByTeam: e.target.value,
        },{
          timeout: 5000,
          withCredentials: true});
        if(fetched.data.status !== "ok") {
          setUpdatingProfile(false);
          return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
        notification(appContextV2.dispatchPanels, "Succès de la mise à jour", "success");
        
      } catch(error) {
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
    }}>
      <option value={0}>★ --</option>
      <option value={1}>★ 1</option>
      <option value={2}>★ 2</option>
      <option value={3}>★ 3</option>
      <option value={4}>★ 4</option>
      <option value={5}>★ 5</option>
    </select>

    </div>
    
    <Spaces n={0}/>
    
    <div className={`text--center window__candidate__white-label cursor--pointer ${isHighlighted(props.inherited.keywords, props.candidate?.candidate?.email) ? "profile--highlighted" : ""}`} onClick={() => {
      if(props.candidate?.candidate?.email) {
        navigator.clipboard.writeText(props.candidate?.candidate?.email);
        notification(appContextV2.dispatchPanels, "Mail copié avec succès !", "success");
      }
    }}>
      {props.candidate?.candidate?.email}
    </div>
    <div className={`text--center window__candidate__white-label ${isHighlighted(props.inherited.keywords, props.candidate?.candidate?.phone) ? "profile--highlighted" : ""}`}>
      {props.candidate?.candidate?.phone ? getFormatedPhone(props.candidate?.candidate?.phone) : ""}
      {/*// <a href={`tel:${props.candidate?.candidate?.phone}`}>{props.candidate?.candidate?.phone}</a>}*/}
    </div>
    
    <Spaces n={0}/>
    
    <div className={"text--center"}>
      <button disabled={updatingProfile} title={"Clique pour déclarer que tu as eu un échange récent avec le candidat. Il passera en vert dans la communauté."} className={"btn-secondary"} onClick={async() => {
        try{
          setUpdatingProfile(true);
          notification(appContextV2.dispatchPanels, "Mise à jour en cours ...", "information");
          const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/pool/cm/update/last/communication`, {
            candidateId: props.inherited.candidateId,
            poolId: appContextV2.appNavigation.poolId,
          },{
            timeout: 5000,
            withCredentials: true});
          if(fetched.data.status !== "ok") {
            setUpdatingProfile(false);
            return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
          }
          setUpdatingProfile(false);
          props.inherited.frontUpdatePool.updateCandidatesBulk([props.inherited.candidateId], {dateOfLastAction: new Date()});
          notification(appContextV2.dispatchPanels, "Information mise à jour avec succès", "success");
        } catch(error) {
          setUpdatingProfile(false);
          devLog(error);
          notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
      }}>
        Échange récent
      </button>
    </div>
    
    <Spaces n={0}/>
    
    <div className={"space-flex--space-between"}>
      <div>
        <span className={"text--minified2x"}>Écoute le marché:</span>
      </div>
      <label className={"switch-V2"}>
        
        <input disabled={updatingProfile} type={"checkbox"} className={"checkbox-on-off"} checked={props.candidate?.candidate?.isListeningToMarket} onChange={async() => {
          try{
            setUpdatingProfile(true);
            // update window front
            props.updateCandidateFrontInWindow({isListeningToMarket: !props.candidate?.candidate?.isListeningToMarket});
            // props.toggleAvailability()
            notification(appContextV2.dispatchPanels, "Mise à jour en cours ...", "information");
            const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/pool/cm/update/listening/to/market`, {
              candidateId: props.inherited.candidateId,
              poolId: appContextV2.appNavigation.poolId,
              value: !props.candidate?.candidate?.isListeningToMarket,
              dateOfListeningToMarket: props.candidate?.candidate?.dateOfListeningToMarket,
            },{
              timeout: 5000,
              withCredentials: true});
            if(fetched.data.status !== "ok") {
              setUpdatingProfile(false);
              return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
            setUpdatingProfile(false);
            // update front pool / mission
            props.inherited.frontUpdatePool.updateCandidatesBulk([props.inherited.candidateId], {isListeningToMarket: !props.candidate?.candidate?.isListeningToMarket});
            props.inherited.frontUpdateMission.updateCandidaciesBulk([props.candidate?.candidacy?._id], {isListeningToMarket: !props.candidate?.candidate?.isListeningToMarket});
            // update front kanban
            notification(appContextV2.dispatchPanels, "Information mise à jour avec succès", "success");
          } catch(error) {
            setUpdatingProfile(false);
            devLog(error);
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
          }
        }}/>
        <span className="slider"> </span>
      </label>
    </div>
    
    <Spaces n={0}/>
    
    <div className={"space-flex--space-between"}>
      <div>
        <span className={"text--minified2x"}>Dispo:</span>
      </div>
      <input disabled={false} type={"date"} onChange={async(e) => {
        try{
          const dateToUpdate = e.target.value;
          setUpdatingProfile(true);
          // update window front
          props.updateCandidateFrontInWindow({dateOfListeningToMarket: new Date(e.target.value)});
          // props.toggleAvailability()
          notification(appContextV2.dispatchPanels, "Mise à jour en cours ...", "information");
          const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/pool/cm/update/candidate/singleton`, {
            candidateId: props.inherited.candidateId,
            poolId: appContextV2.appNavigation.poolId,
            key: "dateOfListeningToMarket",
            value: new Date(dateToUpdate),
          },{
            timeout: 5000,
            withCredentials: true});
          if(fetched.data.status !== "ok") {
            setUpdatingProfile(false);
            return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
          }
          setUpdatingProfile(false);
          // update front pool / mission
          props.inherited.frontUpdatePool.updateCandidatesBulk([props.inherited.candidateId], {dateOfListeningToMarket: new Date(dateToUpdate)});
          props.inherited.frontUpdateMission.updateCandidaciesBulk([props.candidate?.candidacy?._id], {dateOfListeningToMarket: new Date(dateToUpdate)});
          notification(appContextV2.dispatchPanels, "Information mise à jour avec succès", "success");
        } catch(error) {
          setUpdatingProfile(false);
          devLog(error);
          notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
      }} value={props.candidate?.candidate?.dateOfListeningToMarket ? format(new Date(props.candidate?.candidate?.dateOfListeningToMarket), "yyyy-MM-dd") : ""} className={"input-light"}/>
    </div>
    
    {/*{*/}
    {/*  appContextV2.appSession.session.firstName === "dan" && <>*/}
    {/*  */}
    {/*    <Spaces n={1}/>*/}
    {/*    {props.candidate?.candidate?.phone ?*/}
    {/*      <div*/}
    {/*        className={`text--center window__candidate__white-label ${isHighlighted(props.inherited.keywords, props.candidate?.candidate?.phone) ? "profile--highlighted" : ""}`}>*/}
    {/*        {getFormatedPhone(props.candidate?.candidate?.phone)}*/}
    {/*      </div> : ""*/}
    {/*    }*/}
    {/*    <Spaces n={1}/>*/}
    {/*    {props.candidate?.candidate?.phone ? getFormatedPhone(props.candidate?.candidate?.phone) : ""}*/}
    {/*    <Spaces n={1}/>*/}
    {/*    {props.candidate?.candidate?.phone ? (props.candidate?.candidate?.phone) : ""}*/}
    {/*    <Spaces n={1}/>*/}
    {/*    {props.candidate?.candidate?.phone}*/}
    {/*  */}
    {/*  </>*/}
    {/*}*/}
    
    <Spaces n={0}/>
  
  </>);
  
}
