import React from "react";


export default function Burger(props: {size: number, eventOnClick: Function, eventOnHover?: Function, angle?: boolean}) {
  
  let angle: boolean = !!props.angle;
  
  return(
    <button className={`burger btn--hollow ${props.angle ? 'burger--rotated' : ''}`} onClick={(e)=>{
      e.stopPropagation();
      props.eventOnClick();
    }}>
      <svg version="1.1" id="Layer_1" x="0px" y="0px"
           width="20px" height="30px" viewBox="0 0 6 36">
        <circle cx="6" cy="8" r={props.size} stroke="#343434" strokeWidth={"1"} fill="#343434">
        </circle>
        <circle cx="6" cy="19" r={props.size} stroke="#343434" strokeWidth={"1"} fill="#343434">
        </circle>
        <circle cx="6" cy="30" r={props.size} stroke="#343434" strokeWidth={"1"} fill="#343434">
        </circle>
      </svg>
    </button>
  );
}