export {initializeNewMissionWizard};

const initializeNewMissionWizard = [
  
  "assistantStart",
  {
    "1": ["positionWanted", "positionWantedCustom"],
  },
  {
    "1": ["contract", "contractCustom"],
  },
  "fullTime",
  "experience",
  "skills",
  {
    "1": ["asSoonAsPossible", "dateCustom", "duration", "durationCustom", "renewal"],
  },
  {
    "1": ["salaryBy", "salary", "salaryIn", "salaryVar"],
  },
  "advantages",
  {
    "1": ["withCv", "cVMandatory"],
  },
  "remote",
  {
    "1": ["roaming", {
      "1": ["roamingPlace"],
      "2": ["useSavedLocation", {
        "1": ["location"],
      }],
    }],
  },
  "lastAssistantStep",
  "editor",
  "success",
  "error",
];