import React, {useContext, useState} from "react";
import {ButtonBack} from "../../../../../Buttons/ButtonBack";
import Spaces from "../../../../../Spaces";
import {notification} from "../../../../../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../../../../../componentsApp/context/AppContext";
import LoadingStyle2 from "../../../../../Loading/LoadingStyle2";
import axios from "axios";

export {CW2MailChatBox};

const CW2MailChatBox = (props: {
  step: any,
  wizard: any,
  inherited: any
  addEmail: any,
  addSms: any,
}) => {

  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};

  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");

  const isSendSmsActive = appContextV2.appSession.sessionBusiness.creditForSms > 0.2;

  const sendEmail = async() => {

    try {

      setIsLoading(true);

      const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/pool/cma/unique/mail`, {
        candidateId: props.inherited.candidateId,
        emailSubject: subject,
        emailContent: content,
      }, {withCredentials: true});

      if(fetched.data.status !== "ok") {
        setIsLoading(false);
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        return;
      }

      props.addEmail(subject, content);

      setIsSent(true);
      setIsLoading(false);
      props.wizard.goToStep("choice");
      setContent("");
      setSubject("");
      notification(appContextV2.dispatchPanels, "Email envoyé", "success");

    } catch(error) {
      setIsLoading(false);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }

  const sendSms = async() => {
    try {

      setIsLoading(true);

      const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/pool/cma/unique/sms`, {
        candidateId: props.inherited.candidateId,
        content: content,
      }, {withCredentials: true});

      if(fetched.data.status !== "ok") {
        setIsLoading(false);
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        return;
      }

      props.addSms(content);

      setContent("");
      setIsSent(true);
      setIsLoading(false);
      props.wizard.goToStep("choice");
      notification(appContextV2.dispatchPanels, "Email envoyé", "success");

    } catch(error) {
      setIsLoading(false);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }

  const isButtonActive = subject && content && !isLoading && !isSent;

  const isButtonSmsActive = content && content.length <= 120 && !isLoading && !isSent;

  if(props.step === "choice") {

    return(<>
      <button className={"message--v2__chat__button"} onClick={()=>{
        props.wizard.goToStep('email');
      }}>
        Envoyer un email
      </button>
      <button className={"message--v2__chat__button"} disabled={!isSendSmsActive} onClick={()=>{
        props.wizard.goToStep('sms');
      }}>
        Envoyer un SMS
      </button>
    </>);
  }

  if(props.step === "email") {

    return(<div>
      <ButtonBack back={() => {props.wizard.goToStep('choice')}}/>
      <br/>
      <input className={"messages--v2__chat__subject"} value={subject} type={"text"} onChange={(e)=>{
        if(e.target.value?.length < 200) {
          setSubject(e.target.value);
        }
      }} />
      <br/>
      <textarea className={"messages--v2__chat__message"} value={content} onChange={(e)=>{
        if(e.target.value?.length < 500) {
          setContent(e.target.value);
        }
      }}>
      </textarea>
      <button disabled={!isButtonActive} className={"message--v2__chat__button"} onClick={() => {
        sendEmail();
      }}>
        Envoyer
      </button>
      {isLoading && <LoadingStyle2 />}
    </div>);
  }

  if(props.step === "sms") {

    return(<><div>
      <ButtonBack back={() => {props.wizard.goToStep('choice')}}/>
      <Spaces n={0}/>
      <textarea className={"messages--v2__chat__message"} value={content} onChange={(e)=>{
        if(e.target.value?.length < 120) {
          setContent(e.target.value);
        }
      }}>
      </textarea>
      <button disabled={!isButtonSmsActive} className={"message--v2__chat__button"} onClick={() => {
        sendSms();
      }}>
        Envoyer
      </button>
      {isLoading && <LoadingStyle2 />}
    </div><div className={"text--grey"}>{content.length} / 120</div></>);
  }

  return(<></>);
}