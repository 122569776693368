
export {getCandidateColor};

function getCandidateColor(item: any) {
  
  if(!item.dateOfLastAction) return "";
  
  const dateOfLastActionInS: any = Math.round((new Date(item.dateOfLastAction).getTime() / 1000));
  const delta = Math.round(new Date().getTime() / 1000) - dateOfLastActionInS;
  
  // > 1 year
  if(delta > 354 * 24 * 60 * 60) {
    return "pool-template__candidate--old5";
  }
  
  // > 6 months
  if(delta > 180 * 24 * 60 * 60) {
    return "pool-template__candidate--old4";
  }
  
  // > 3 months
  if(delta > 90 * 24 * 60 * 60) {
    return "pool-template__candidate--old3";
  }
  
  // > 1 months
  if(delta > 30 * 24 * 60 * 60) {
    return "pool-template__candidate--old2";
  }
  
  // < 1 month
  if(delta > 0 * 24 * 60 * 60) {
    return "pool-template__candidate--old1";
  }
  
  return "";
}