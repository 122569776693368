import React, {useContext, useEffect, useState} from "react";
import FormEditorNav from "./componentsMissionFormEditorV2/FormEditorNav";
import AppContext, {IAppV2Context} from "../../componentsApp/context/AppContext";
import {notification} from "../../../utils/notification";
import LoadingStyle2 from "../../sharedComponents/components/Loading/LoadingStyle2";
import axios from "axios";
import Spaces from "../../sharedComponents/components/Spaces";
import EditorAddQuestion from "./componentsMissionFormEditorV2/EditorAddQuestion";
import EditorQuestion from "./componentsMissionFormEditorV2/EditorQuestion";
import FormEditorNavUpdate from "./componentsMissionFormEditorV2/FormEditorNavUpdate";
import {FormEditorOptions} from "./componentsMissionFormEditorV2/FormEditorOptions";
import dragQuestion from "./componentsMissionFormEditorV2/dependencies/dragAndDrop/dragQuestion";
import dropQuestion from "./componentsMissionFormEditorV2/dependencies/dragAndDrop/dropQuestion";
import dropQuestionOnEmptyFront from "./componentsMissionFormEditorV2/dependencies/dragAndDrop/dropQuestionOnEmptyFront";
import functionDeleteQuestionFront from "./componentsMissionFormEditorV2/dependencies/updateDirectory/functionDeleteQuestionFront";
import functionToggleExtendedFront from "./componentsMissionFormEditorV2/dependencies/updateDirectory/functionToggleExtendedFront";
import functionUpdateQuestionFront from "./componentsMissionFormEditorV2/dependencies/updateDirectory/functionUpdateQuestionFront";
import EmptyItemForm from "./componentsMissionFormEditorV2/EmptyItemForm";
import EditorSumUpV2 from "./componentsMissionFormEditorV2/EditorSumUpV2";
import functionCloseAllFront from "./componentsMissionFormEditorV2/dependencies/updateDirectory/functionCloseAllFront";
import {useForm} from "use-formidable";
import {devLog} from "../../../utils/devLog/devLog";
import {withRouter} from "react-router-dom";

function MissionFormEditorV2(props: {
  editorMode: 'creation' | 'edition',
  isPageLoading: boolean,
  presets: any,
  form: any,
  setForm: Function,
  timeStamps: any,
  wizard: any,
  setMissionTokens: any,
  saveCallback: Function,
  setPresets: Function,
}) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  /** Informations about dragged questions */
  const [dragged, setDragged] = useState({
    draggedFromFormId: "",
    draggedFromIndex: 0,
  });
  
  /** Jobdesc */
  const [jobDesc, setJobDesc, formidableJobDesc] = useForm({file: null});
  const [fetchingFile, setFetchingFile] = useState(false);
  
  const [isLocallyLoading, setIsLocallyLoading] = useState(false);
  
  const areButtonsActive: boolean = !props.isPageLoading && !fetchingFile && !isLocallyLoading;
  
  /** Handles file dropping */
  useEffect(() => {
    (() => {
      if(!!jobDesc.file) {
        if(jobDesc.file.length > 1) {
          setJobDesc()({target: {type: "custom", name: "file", value: null}});
          return notification(appContextV2.dispatchPanels, "Un seul fichier peut être envoyé à la fois", "error");
        }
        if(!formidableJobDesc.isFileMime(jobDesc.file[0], ["pdf", "docx"])) {
          setJobDesc()({target: {type: "custom", name: "file", value: null}});
          return notification(appContextV2.dispatchPanels, "Le type du fichier n'est pas pris en charge", "error");
        }
        if(!formidableJobDesc.isFileSmallerThan(jobDesc.file[0], 9_000_000)) {
          setJobDesc()({target: {type: "custom", name: "file", value: null}});
          return notification(appContextV2.dispatchPanels, "Le fichier est trop lourd (maximum 8mo)", "error");
        }
        return notification(appContextV2.dispatchPanels, "Fichier accepté", "success");
      }
    })();
  }, [jobDesc.file]);
  
  /** Function to save the jobDesc, always call it before saving document */
  const saveJobDesc = async() => {
    try {
      setFetchingFile(true);
      const dataToSend: any = new FormData();
      dataToSend.append("jobdesc", jobDesc.file[0], jobDesc.file[0].name);
      const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/upload/jobdesc`, dataToSend, {
        withCredentials: true, headers: {
          'accept': 'application/json', 'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data; boundary=${dataToSend._boundary}`,
        }
      });
      if(fetched.data.status !== "ok") {
        setFetchingFile(false);
        notification(appContextV2.dispatchPanels, "Le fichier n'a pas été ajouté", "warning");
        return "";
      }
      setFetchingFile(false);
      props.setForm()({target: {type: 'custom', name: 'documentUrl', value: fetched.data.data}});
      console.log("FETCHED FILE ---", fetched.data.data);
      return(fetched.data.data);
    } catch(error) {
      setFetchingFile(false);
      notification(appContextV2.dispatchPanels, "Le fichier n'a pas été ajouté", "warning");
      return "";
    }
  }
  
  const duplicate = async() => {
    try {
      const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/ent/m/duplicate`, {
        missionId: appContextV2.appNavigation.missionId,
      }, {
        withCredentials: true,
      });
      if(fetched.data.status !== "ok") {
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "warning");
        return;
      }
      //@ts-ignore
      props.history.push('/');
      notification(appContextV2.dispatchPanels, "Succès de la duplication", "success");
    } catch(error) {
      devLog(error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "warning");
    }
  }
  
  /** Drag and drop questions */
  const drag = (draggedFromFormId: string, draggedFromIndex: number) => dragQuestion(setDragged, draggedFromFormId, draggedFromIndex,);
  const drop = (draggedToFormId: string, draggedToIndex: number) => dropQuestion({dragged, draggedToFormId, draggedToIndex, props,});
  const dropOnEmptyFront = (draggedToFormId: string) => dropQuestionOnEmptyFront({dragged, draggedToFormId, props,});
  
  /** Update front */
  const deleteQuestionFront = functionDeleteQuestionFront(appContextV2, props);
  const toggleExtendedFront = functionToggleExtendedFront(props);
  const updateQuestionFront = functionUpdateQuestionFront(props);
  const closeAllFront = functionCloseAllFront(props);
  
  return (<main>
    
    {/*--------------------------- information */}
    
    {/*--------------------------- actions */}
    {props.editorMode === 'creation' && <FormEditorNav reduce={closeAllFront}
                                                       currentStatus={""}
                                                       jobDesc={jobDesc}
                                                       saveJobDesc={saveJobDesc}
                                                       areButtonsActive={areButtonsActive}
                                                       publish={props.saveCallback}/>}
    {props.editorMode === 'edition' && <FormEditorNavUpdate reduce={closeAllFront}
                                                            currentStatus={""}
                                                            form={props.form}
                                                            jobDesc={jobDesc}
                                                            saveJobDesc={saveJobDesc}
                                                            areButtonsActive={areButtonsActive}
                                                            duplicate={duplicate}
                                                            publish={props.saveCallback}/>}
                                                            
    <div>{props.isPageLoading && <LoadingStyle2/>}</div>
    
    <Spaces n={1}/>
    
    {/*--------------------------- form 1 */}
    <section className={"editor-v2__section"}>
      
      <article className={"editor-v2__section__title"}>Questions génériques</article>
      
      {props.form?.form?.map((item: any, key: number) => (<EditorQuestion {...props}
                                                                          item={item}
                                                                          index={key}
                                                                          key={key}
                                                                          id={"form"}
                                                                          drag={drag}
                                                                          drop={drop}
                                                                          toggleExtended={toggleExtendedFront}
                                                                          updateQuestion={updateQuestionFront}
                                                                          deleteQuestion={deleteQuestionFront}/>))}
      
      {props.form.form?.length === 0 && <EmptyItemForm id={"form"} dropOnEmpty={dropOnEmptyFront}/>}
      
      {props.form?.form?.length < 20 && <EditorAddQuestion {...props} dropOnEmpty={dropOnEmptyFront} id={"form"}/>}
    
    </section>
    
    <Spaces n={0}/>
    
    {/*--------------------------- form 2 */}
    <section className={"editor-v2__section"}>
      
      <article className={"editor-v2__section__title"}>Questions techniques</article>
      
      {props.form?.form2?.map((item: any, key: number) => (<EditorQuestion {...props}
                                                                           item={item}
                                                                           index={key}
                                                                           key={key}
                                                                           id={"form2"}
                                                                           toggleExtended={toggleExtendedFront}
                                                                           drag={drag}
                                                                           drop={drop}
                                                                           updateQuestion={updateQuestionFront}
                                                                           deleteQuestion={deleteQuestionFront}/>))}
      
      {props.form.form2?.length === 0 && <EmptyItemForm id={"form2"} dropOnEmpty={dropOnEmptyFront}/>}
      
      {props.form?.form2?.length < 20 && <EditorAddQuestion {...props} dropOnEmpty={dropOnEmptyFront} id={"form2"}/>}
    
    </section>
    
    <Spaces n={0}/>
    
    {/*--------------------------- sumup */}
    <EditorSumUpV2 {...props} jobDesc={jobDesc} setJobDesc={setJobDesc}/>
    
    <Spaces n={0}/>
    
    {/*--------------------------- options */}
    <FormEditorOptions {...props} />
    
    <Spaces n={1}/>
    
    {/*--------------------------- actions */}
    {props.editorMode === 'creation' && <FormEditorNav reduce={closeAllFront}
                                                       currentStatus={""}
                                                       jobDesc={jobDesc}
                                                       saveJobDesc={saveJobDesc}
                                                       areButtonsActive={areButtonsActive}
                                                       publish={props.saveCallback}/>}
    {props.editorMode === 'edition' && <FormEditorNavUpdate reduce={closeAllFront}
                                                            currentStatus={""}
                                                            jobDesc={jobDesc}
                                                            form={props.form}
                                                            saveJobDesc={saveJobDesc}
                                                            duplicate={duplicate}
                                                            areButtonsActive={areButtonsActive}
                                                            publish={props.saveCallback}/>}
    
    <div>{props.isPageLoading && <LoadingStyle2/>}</div>
    
    <Spaces n={1}/>
  
  </main>)
}

// @ts-ignore
export default withRouter(MissionFormEditorV2);
