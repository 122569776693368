
import React from 'react';
import AppContext, {IAppContext, IAppV2Context} from "../../componentsApp/context/AppContext";
import {useContext} from "react";


export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return(<section className={`notification`}>
    <article className={`notification__text ${getColor(appContextV2.appPanels.notifications.notificationType)}`}>
      {appContextV2.appPanels.notifications.notificationText}
    </article>
  </section>);
  
}

function getColor(type: "error"|"warning"|"information"|"success") {
  
  switch(type) {
    
    case 'error':
      return 'notification__text--error';
      
    case 'warning':
      return 'notification__text--warning';
      
    case 'success':
      return 'notification__text--success';
      
    default:
      return 'notification__text--information';
  }
  
}
