import React, {useContext, useEffect, useState} from "react";
import {devLog} from "../../../../utils/devLog/devLog";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import SubPageLayout from "../../../sharedComponents/layouts/SubPageLayout";
import axios from "axios";
import {notification} from "../../../../utils/notification";
import {NotOnSmartphones} from "../../../sharedComponents/components/NotOnSmartphones";
import MissionKanbanNav from "./componentsRecruiterMissionKanban/MissionKanbanNav";
import getContract from "../../../../utils/getContract";
import {capitalizeSentence} from "../../../../utils/converters";
import Spaces from "../../../sharedComponents/components/Spaces";
import AbstractKanbanColumnV2 from "./componentsRecruiterMissionKanban/AbstractKanbanColumnV2";
import {useForm} from "use-formidable";
import moveFromIndexToIndex from "../../../../utils/moveFromIndexToIndex";
import fnMoveUnsubscribedCandidacyFront
  from "./dependenciesRecruiterMissionKanban/frontUpdates/fnMoveUnsubscribedCandidacyFront";
import fnAddCandidacyFront from "./dependenciesRecruiterMissionKanban/frontUpdates/fnAddCandidacyFront";
import fnAddColumnFront from "./dependenciesRecruiterMissionKanban/frontUpdates/fnAddColumnFront";
import fnSetColumnId from "./dependenciesRecruiterMissionKanban/frontUpdates/fnSetColumnId";
import fnDeleteColumnFront from "./dependenciesRecruiterMissionKanban/frontUpdates/fnDeleteColumnFront";
import fnUpdateColumnFront from "./dependenciesRecruiterMissionKanban/frontUpdates/fnUpdateColumnFront";
import fnSetNewColumn from "./dependenciesRecruiterMissionKanban/frontUpdates/fnSetNewColumn";
import fnMoveColumnFront from "./dependenciesRecruiterMissionKanban/frontUpdates/fnMoveColumnFront";
import fnMoveCandidacyFront from "./dependenciesRecruiterMissionKanban/frontUpdates/fnMoveCandidacyFront";
import fnUpdateCandidaciesBulk from "./dependenciesRecruiterMissionKanban/frontUpdates/fnUpdateCandidaciesBulk";
import fnRemoveUnregisteredCandidacyFront
  from "./dependenciesRecruiterMissionKanban/frontUpdates/fnRemoveUnregisteredCandidacyFront";
import fnUpdateUnregisteredCandidacyFront
  from "./dependenciesRecruiterMissionKanban/frontUpdates/fnUpdateUnregisteredCandidacyFront";
import getGlobalMark from "./dependenciesRecruiterMissionKanban/helpers/getGlobalMark";
import onDragCandidacy from "./dependenciesRecruiterMissionKanban/dragAndDrop/onDragCandidacy";
import onDropCandidacy from "./dependenciesRecruiterMissionKanban/dragAndDrop/onDropCandidacy";
import fetchShortlist from "./dependenciesRecruiterMissionKanban/fetchers/fetchShortlist";
import filterCandidacies from "./dependenciesRecruiterMissionKanban/filterCandidacies";

export default (props: any) => {

  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

  /** Fetch shortlist, mission and business profiles */
  const [fetchStatus, setFetchStatus]: ["asleep" | "fetching" | "fetched" | "error", Function] = useState("asleep");
  const [fetchedData, updateFetchedData, formidableFetchedData] = useForm({
    businessProfile: {},
    missionProfile: {},
    shortlist: [],
    shortlistUnsubscribed: [],
  });
  const [displayedShortlist, setDisplayedShortlist]: [any, any] = useState([]);
  const [displayedUnsubscribedShortlist, setDisplayedUnsubscribedShortlist]: [any, any] = useState([]);
  devLog(displayedShortlist);

  /** Filters */
  const [filterByWords, setFilterByWords] = useState("");
  const [filterByMark, setFilterByMark] = useState("");
  const areFiltersActive = !!filterByWords || !!filterByMark;

  /** Dragged candidacy */
  const [dragged, setDragged] = useState("");
  const [draggedType, setDraggedType]: [string, Function] = useState("");
  const [draggedFromColumnId, setDraggedFromColumnId] = useState("");
  useEffect(() => {
    setDraggedFromColumnId(displayedShortlist?.find((item: any) => (item._id === dragged))?.kanbanColumn);
  }, [dragged]);
  devLog(draggedFromColumnId);

  /** Used to disable buttons when actions are operated */
  const [isLoading, setIsLoading] = useState(false);

  /** Update displayed users according to filters */
  useEffect(() => {
    filterCandidacies({
      fetchedData,
      filterByWords,
      filterByMark,
      setDisplayedShortlist,
    });
  }, [fetchedData.shortlist, filterByWords, filterByMark]);

  /** Fetch shortlist and business + mission profiles */
  useEffect(() => {
    fetchShortlist({
      missionId: props.match.params.missionid,
      setFetchStatus,
      formidableFetchedData,
      setDisplayedUnsubscribedShortlist,
      dispatchPanels: appContextV2.dispatchPanels,
    });
  }, []);
  devLog("fetchedData", fetchedData);

  /**
   * Front updates:
   * - columns
   * - candidacies
   */
  const frontUpdates = {
    addColumnFront: fnAddColumnFront({updateFetchedData, fetchedData, dispatchPanels: appContextV2.dispatchPanels}),
    setsColumnId: fnSetColumnId({updateFetchedData, fetchedData}),
    deleteColumnFront: fnDeleteColumnFront({updateFetchedData, fetchedData}),
    updateColumnFront: fnUpdateColumnFront({updateFetchedData, fetchedData}),
    setNewColumns: fnSetNewColumn({updateFetchedData, fetchedData}),
    moveColumnFront: fnMoveColumnFront({updateFetchedData, fetchedData}),
    moveCandidacyFront: fnMoveCandidacyFront({updateFetchedData, fetchedData}),
    moveUnsubscribedCandidacyFront: fnMoveUnsubscribedCandidacyFront(setDisplayedUnsubscribedShortlist),
    addCandidacyFront: fnAddCandidacyFront(formidableFetchedData, fetchedData),
    updateCandidaciesBulk: fnUpdateCandidaciesBulk({formidableFetchedData, fetchedData}),
    removeUnregisteredCandidacyFront: fnRemoveUnregisteredCandidacyFront({setDisplayedUnsubscribedShortlist}),
    updateUnregisteredCandidacyFront: fnUpdateUnregisteredCandidacyFront({setDisplayedUnsubscribedShortlist}),
  };

  const drag = onDragCandidacy({
    setDraggedType,
    setDragged
  });

  const drop = onDropCandidacy({
    draggedType,
    displayedShortlist,
    dragged,
    fetchedData,
    setIsLoading,
    frontUpdates,
    draggedFromColumnId,
    appContextV2,
  });

  return (<SubPageLayout status={fetchStatus} subPage={props.subPage}>

    <NotOnSmartphones/>

    <section className={"kanban-template hide-on-smartphones"}>
      <article className={"kanban-template__body"}>
        <div className={"kanban-job-title"}>
          Candidats au poste
          de: {capitalizeSentence(fetchedData.missionProfile?.positionWanted)}, {getContract(fetchedData.missionProfile?.contract)}
        </div>

        <Spaces n={0}/>

        <div className={"kanban"}>

          {/* -------------------- Basic column 1 ----------------------------*/}
          <AbstractKanbanColumnV2 columnId={"1"}
                                  columnData={{}}
                                  rank={1}
                                  draggedFromColumnId={draggedFromColumnId}
                                  keywords={filterByWords}
                                  areFiltersActive={areFiltersActive}
                                  setIsLoading={setIsLoading}
                                  title={"Candidatures reçues"}
                                  drag={drag}
                                  drop={drop}
                                  missionId={fetchedData.missionProfile?.missionId}
                                  customKanbanView={fetchedData.missionProfile?.customKanbanView}
                                  shortlist={displayedShortlist?.filter((item2: any) => (item2.kanbanColumn === "1"))}
                                  shortlistUnsubscribed={displayedUnsubscribedShortlist?.filter((item2: any) => (!item2.column || item2.column === "1")) || []}
                                  frontUpdates={frontUpdates}
          />

          {/*/!* -------------------- Custom columns ----------------------------*!/*/}
          {fetchedData.missionProfile?.customKanbanView?.map((item: any, key: number) => (
            <AbstractKanbanColumnV2 key={key}
                                    columnData={item}
                                    draggedFromColumnId={draggedFromColumnId}
                                    keywords={filterByWords}
                                    columnId={item._id}
                                    rank={key + 2}
                                    areFiltersActive={areFiltersActive}
                                    setIsLoading={setIsLoading}
                                    title={item.name}
                                    drag={drag}
                                    drop={drop}
                                    missionId={fetchedData.missionProfile?.missionId}
                                    customKanbanView={fetchedData.missionProfile?.customKanbanView}
                                    shortlist={displayedShortlist?.filter((item2: any) => (item2.kanbanColumn === item._id))}
                                    shortlistUnsubscribed={displayedUnsubscribedShortlist?.filter((item2: any) => (item2.column === item._id)) || []}
                                    frontUpdates={frontUpdates}
            />))}

          {/*/!* -------------------- Basic column 10 ----------------------------*!/*/}
          <AbstractKanbanColumnV2 columnId={"10"}
                                  columnData={{}}
                                  draggedFromColumnId={draggedFromColumnId}
                                  areFiltersActive={areFiltersActive}
                                  keywords={filterByWords}
                                  setIsLoading={setIsLoading}
                                  rank={fetchedData.missionProfile?.customKanbanView?.length + 2}
                                  title={"Recrutements"}
                                  drag={drag}
                                  drop={drop}
                                  missionId={fetchedData.missionProfile?.missionId}
                                  customKanbanView={fetchedData.missionProfile?.customKanbanView}
                                  shortlist={displayedShortlist?.filter((item2: any) => (item2.kanbanColumn === "10"))}
                                  shortlistUnsubscribed={displayedUnsubscribedShortlist?.filter((item2: any) => (item2.column === "10")) || []}
                                  frontUpdates={frontUpdates}
          />

        </div>

      </article>

      <article className={"kanban-template__nav"}>

        {/*------------------------------------------------------- Navbar ------------------------------------*/}
        <MissionKanbanNav frontUpdates={frontUpdates}
                          setIsLoading={setIsLoading}
                          setFilterByWords={setFilterByWords}
                          setFilterByMark={setFilterByMark}
                          filterByWords={filterByWords}
                          filterByMark={filterByMark}
                          numberOfColumns={fetchedData.missionProfile?.customKanbanView?.length}
                          isLoading={isLoading}
                          numberOfCandidates={fetchedData?.shortlist?.length || 0}
                          numberOfCandidates1={fetchedData?.shortlist.filter((item: any) => (getGlobalMark(item.markSkills, item.markForm) <= 100 && getGlobalMark(item.markSkills, item.markForm) >= 90 && !item.imported)).length || 0}
                          numberOfCandidates2={fetchedData?.shortlist.filter((item: any) => (getGlobalMark(item.markSkills, item.markForm) < 90 && getGlobalMark(item.markSkills, item.markForm) >= 70 && !item.imported)).length || 0}
                          numberOfCandidates3={fetchedData?.shortlist.filter((item: any) => (getGlobalMark(item.markSkills, item.markForm) < 70 && getGlobalMark(item.markSkills, item.markForm) >= 50 && !item.imported)).length || 0}
                          numberOfCandidates4={fetchedData?.shortlist.filter((item: any) => (getGlobalMark(item.markSkills, item.markForm) < 50 && !item.imported)).length || 0}
                          numberOfCandidatesImported={fetchedData?.shortlist.filter((item: any) => item.imported).length || 0}
                          numberOfCandidatesBlocked={fetchedData?.shortlist.filter((item: any) => (item.blockedByAlgorithm || item.status === "blocked")).length || 0}
        />

      </article>

    </section>

  </SubPageLayout>);
}
