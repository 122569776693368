import {IAppV2Panels, IAppV2Session} from "./IAppV2";

export {initialPanels};

const initialPanels: IAppV2Panels = {
  window: {
    isWindowVisible: false,
    isWindowClosing: false,
    windowOptions: {
      type: '',
      data: {},
    },
  },
  menuFromBottom: {
    isMenuFromBottomVisible: false,
    isMenuFromBottomClosing: false,
    menuFromBottomOptions: {
      type: '',
      data: {},
    },
  },
  editorPanel: {
    isEditorPanelVisible: false,
    editorPanelOptions: {
      type: '',
      data: {}
    },
  },
  cookies: {
    isWindowCookiesVisible: false,
  },
  notifications: {
    isWindowNotificationVisible: false,
    notificationText: "string",
    notificationType: "information",
    notificationTimer: null,
  }
}