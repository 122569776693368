import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";
import SubPageLayout from "../../../../layouts/SubPageLayout";
import Spaces from "../../../../components/Spaces";
import {Link} from "react-router-dom";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<SubPageLayout status={"ok"} subPage={props.subPage}>
    
    <Spaces n={2}/>
    
    <div className={"apply-V2__title"}>
      Une erreur est survenue
    </div>
    
    <Spaces n={0}/>
    
    <section className={"container text--center"}>
      
      <article className={"white-container white-container--grey"}>
        
        Une erreur a interompu le cours normal de la candidature.
        
        <br/><br/>
        
        Nous sommes désolé de la gêne occasionnée, n'hésitez pas à contacter nos équipes.
        
        
        {props.candidacy.status === "tooManyApplies" && <><br/><br/>Vous avez déjà postulé pour cette offre</>}
      
      </article>
    
    </section>
    
    <Spaces n={0}/>
    
    <div className={"text--center"}>
      <Link to={`/`}>
        <button className={"btn-secondary"}>
          Retour
        </button>
      </Link>
    </div>
  
  
  </SubPageLayout>);
}