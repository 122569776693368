import React, {useEffect, useReducer, useState,} from "react";
import "./componentsApp/style/App.scss";
import {BrowserRouter as Router} from "react-router-dom";
import {AppProvider, IAppV2Context} from "./componentsApp/context/AppContext";
import {IAppV2Navigation, IAppV2Panels, IAppV2Props, IAppV2Session} from "./componentsApp/state/IAppV2";
import {initialSession} from "./componentsApp/state/initialSession";
import {initialNavigation} from "./componentsApp/state/initialNavigation";
import {initialPanels} from "./componentsApp/state/initialPanels";
import {sessionReducer} from "./componentsApp/reducers/sessionReducer";
import {navigationReducer, TNaviationReducers} from "./componentsApp/reducers/navigationReducer";
import {panelsReducer} from "./componentsApp/reducers/panelsReducer";
import welcomeConsole from "../utils/dom/welcomeConsole";
import {escapeKeyListener} from "../utils/listeners/escapeKeyListener";
import {SwitchAppStatus} from "./componentsApp/SwitchAppStatus";
import {devLog} from "../utils/devLog/devLog";
import {notification} from "../utils/notification";
import axios from "axios";
import {fetchSession} from "./componentsApp/fetchers/fetchSession";
import setSession from "./componentsApp/fetchers/setSession";

export {AppV2};

const AppV2 = (props: IAppV2Props) => {
  
  useEffect(() => {welcomeConsole();}, []);
  
  /** ----- App state */
  const [appSession, dispatchSession]: [IAppV2Session, (action: { type: string, value: any }) => void,] = useReducer(sessionReducer, initialSession);
  const [appNavigation, dispatchNavigation]: [IAppV2Navigation, (action: { type: TNaviationReducers, value: any }) => void,] = useReducer(navigationReducer, initialNavigation);
  const [appPanels, dispatchPanels]: [IAppV2Panels, (action: { type: string, value: any }) => void,] = useReducer(panelsReducer, initialPanels);
  const [globalResearch, setGlobalResearch]: [string, Function] = useState("");
  
  /** ----- Session fetcher */
  const [sessionFetchedStatus, setSessionFetchedStatus] = useState("asleep");
  const [fetchedSession, setFetchedSession] = useState({});
  
  /** ----- Fetches current session */
  useEffect(() => {fetchSession({dispatchPanels, setSessionFetchedStatus, setFetchedSession})()}, []);
  
  /** ----- Sets session */
  useEffect(() => {setSession({sessionFetchedStatus, fetchedSession, dispatchSession})}, [sessionFetchedStatus]);
  
  /** ----- Add the escape key listener */
  useEffect(escapeKeyListener(() => {
    if(appPanels.notifications.isWindowNotificationVisible) {
      dispatchPanels({type: "CLOSE_NOTIFICATIONS", value: null});
    } else {
      dispatchPanels({type: "CLOSE_ALL", value: null});
    }
  }), []);
  
  /** ----- Sets App context */
  let contextValue: IAppV2Context = {
    appSession: appSession,
    appNavigation: appNavigation,
    appPanels: appPanels,
    globalResearch: globalResearch,
    setGlobalResearch: setGlobalResearch,
    dispatchSession: dispatchSession,
    dispatchNavigation: dispatchNavigation,
    dispatchPanels: dispatchPanels,
    fetchSession: fetchSession({dispatchPanels, setSessionFetchedStatus, setFetchedSession}),
  };
  devLog("contextValue", contextValue);
  
  return (<AppProvider value={contextValue}>
    
    <Router>
      
      <SwitchAppStatus/>
      
    </Router>
    
  </AppProvider>);
  
}
