import React, {useContext, useEffect, useState} from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import Spaces from "../../../sharedComponents/components/Spaces";
import {ButtonBack} from "../../../sharedComponents/components/Buttons/ButtonBack";

export default (props: IAssistantContext) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<section>
    
    <Spaces n={0}/>
    
    <div className={"assistant--v2-container--no-flex"}>
      <div className={"space-light"}>
        <ButtonBack back={() => props.wizard.previousStep()}/>
      </div>
    </div>
    
    <div className={"assistant--v2-title"}>
      Veux tu recruter sans CV ?
    </div>
    
    <Spaces n={1}/>
    
    <div className={"assistant--v2-container"}>
      
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "withoutCv", value: false}});
                props.wizard.goToStep('cVMandatory');
              }}>
        Recrutement avec CV
      </button>
      
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "withoutCv", value: true}});
                props.wizard.goToStep('remote');
              }}>
        Recrutement sans CV
      </button>
      
    </div>
    
    <Spaces n={1}/>
    
    <div className={"text--center text--grey assistant--v2-container--no-flex"}>
      Le recrutement sans CV a le vent en poupe ! L'étape suivante te permettra de déterminer si le cv est facultatif ou obligatoire si tu veux tout de même que le candidat ai la possibilité d'en transmettre un.
    </div>
  
  </section>);
}