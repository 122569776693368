export default (args: {formidableFetchedData: any, fetchedData: any}) => (ids: string[], keyValuesPairs: any) => {

  let newCandidacies = [];
  for (let candidacy of args.fetchedData.shortlist) {
    if (!ids.includes(candidacy._id)) {
      newCandidacies.push({...candidacy});
    } else {
      newCandidacies.push({
        ...candidacy,
        ...keyValuesPairs,
      });
    }
  }
  args.formidableFetchedData.setForm({
    ...args.fetchedData,
    shortlist: newCandidacies,
  });
}