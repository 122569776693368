import React, {useContext, useEffect, useState} from "react";
import Spaces from "../../sharedComponents/components/Spaces";
import {notification} from "../../../utils/notification";
import AppContext, {IAppV2Context} from "../../componentsApp/context/AppContext";
import fetchAmbassadors from "../componentsRecruiterAmbassadors/dependenciesRecruiterAmbasssadors/fetch/fetchAmbassadors";

export default (props: {
  missionTokens: {
    rank: number,
    mailHandler: string,
  },
  white?: boolean
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [tracking, setTracking] = useState("");
  const [ambassador, setAmbassador] = useState("");
  const [ambassadors, setAmbassadors] = useState([]);
  
  useEffect(() => {fetchAmbassadors(() => {}, setAmbassadors)}, []);
  
  const url: string = `${window.location.origin}/apply/${appContextV2.appSession.sessionBusiness.profileLink.name}/${appContextV2.appSession.sessionBusiness.profileLink.number}/${props.missionTokens.rank}`;
  const urlWithTracking: string = `${window.location.origin}/apply/${appContextV2.appSession.sessionBusiness.profileLink.name}/${appContextV2.appSession.sessionBusiness.profileLink.number}/${props.missionTokens.rank}${(!!tracking || !!ambassador) ? "?" : ""}${(!!tracking) ? `o=${tracking}` : ""}${(!!tracking && !!ambassador) ? "&" : ""}${(!!ambassador) ? `a=${ambassador}` : ""}`;
  
  return(<>
  
    <article className={`white-container ${!props.white ? "white-container--grey" : ""}`}>
    
      <div>
        <span className={"text--bold text--magnified11x"}>Copie le lien.</span> Les candidats pourront postuler depuis un smartphone, une tablette ou un
        ordinateur
        en suivant ce lien.
      </div>
    
      <Spaces n={1}/>
    
      <div className={"text--center"}>
        <div className={`link-box2 ${!props.white ? "link-box2--white" : ""}`}>
          {url}
        </div>
      </div>
    
      <Spaces n={1}/>
    
      <div className={"text--center"}>
        <button className={"btn-secondary"} onClick={() => {
          notification(appContextV2.dispatchPanels, "Lien copié", "success");
          navigator.clipboard.writeText(url);
        }}>
          Copier le lien
        </button>
      </div>
  
    </article>
  
    <Spaces n={0}/>
  
    <article className={`white-container ${!props.white ? "white-container--grey" : ""}`}>
    
      <div><span className={"text--bold text--magnified11x"}>Active le tracking.</span> Tu peux générer un lien unique pour suivre la provenance des candidats
        et/ou activer la cooptation. Attention, ce lien est unique pour chaque plateforme et/ou ambassadeur.
      </div>
        <button className={"btn-secondary"} onClick={() => {
          notification(appContextV2.dispatchPanels, "Lien copié", "success");
          navigator.clipboard.writeText(urlWithTracking);
        }}>
          Copier le lien avec tracking
        </button>

    
      <Spaces n={1}/>
    
      <div className={"text--center"}>
        <select className={"input--v2"} value={tracking} onChange={(e) => {setTracking(e.target.value)}}>
          <option value={""}>Lien neutre (aucune plateforme)</option>
          {["linkedin", "facebook", "twitter", "indeed", "monster", "whatsapp", "slack"].sort().map((item: any, key: number) => (
            <option key={key} value={item}>
              {item}
            </option>))}
        </select>
        {
          (!!ambassadors && ambassadors.length !== 0) && <select className={"input--v2"} value={ambassador} onChange={(e) => {setAmbassador(e.target.value)}}>
            <option value={""}>Lien neutre (aucun ambassadeur)</option>
            {ambassadors.map((item: any, key: number) => (<option key={key} value={item.slug}>
              {item.fullName}
            </option>))}
          </select>
        }
      
        <Spaces n={1}/>
      
        <button className={"btn-secondary"} onClick={() => {
          notification(appContextV2.dispatchPanels, "Lien copié", "success");
          navigator.clipboard.writeText(urlWithTracking);
        }}>
          Copier le lien avec tracking
        </button>
    
      </div>
  
    </article>
  
    <Spaces n={0}/>
  
    <article className={`white-container ${!props.white ? "white-container--grey" : ""}`}>
    
      <div>
        <span className={"text--bold text--magnified11x"}>Copie le mail automatique de relance.</span> Sur certaines plateformes il n'est pas possible de
        mettre un lien pour le candidat. Utilise cet email à la place ou en plus du tiens pour recevoir les notifications de nouveaux candidats. ils seront
        automatiquement relancés. Son fonctionnement a été validé sur les plateformes suivantes: Indeed, Apec, RégionJob.
      </div>
    
      <Spaces n={1}/>
    
      <div className={"text--center"}>
        <div className={`link-box2 ${!props.white ? "link-box2--white" : ""}`}>
          {`${props.missionTokens.mailHandler}@follow.myshortlist.co`}
        </div>
      </div>
    
      <Spaces n={1}/>
    
      <div className={"text--center"}>
        <button className={"btn-secondary"} onClick={() => {
          notification(appContextV2.dispatchPanels, "Lien copié", "success");
          navigator.clipboard.writeText(`${props.missionTokens.mailHandler}@follow.myshortlist.co`);
        }}>
          Copier l'email de relance
        </button>
      </div>
  
    </article>
  
    <Spaces n={0}/>
  
    <article className={`white-container ${!props.white ? "white-container--grey" : ""}`}>
      <span className={"text--bold text--magnified11x"}>Où publier le lien ?</span> Le lien peut être publié sur tout support: de whatsapp a votre page
      carrière en passant par les plateformes plus classiques.
      <Spaces n={0}/>
      <div className={"text--center"}>
        <a href={"https://www.linkedin.com/"} title={"https://www.linkedin.com/"} target={"_blank"}>
          <button className={"btn-social--share btn-social--share--linkedin"}>Linkedin</button>
        </a>
        <a href={"https://www.facebook.com/"} title={"https://www.facebook.com/"} target={"_blank"}>
          <button className={"btn-social--share btn-social--share--facebook"}>Facebook</button>
        </a>
        <a href={"https://twitter.com/"} title={"https://www.twitter.com/"} target={"_blank"}>
          <button className={"btn-social--share btn-social--share--twitter"}>Twitter</button>
        </a>
        <Spaces n={0}/>
        <a href={"https://www.indeed.com/"} title={"https://www.indeed.com/"} target={"_blank"}>
          <button className={"btn-social--share btn-social--share--indeed"}>Indeed</button>
        </a>
      
        <a href={"https://www.monster.fr/"} title={"https://www.monster.fr/"} target={"_blank"}>
          <button className={"btn-social--share btn-social--share--monster"}>Monster</button>
        </a>
      
        <a href={"https://www.cadremploi.fr/"} title={"https://www.cadremploi.fr/"} target={"_blank"}>
          <button className={"btn-social--share btn-social--share--cadreemploi"}>Cadreemploi</button>
        </a>
      
        <a href={"https://www.apec.fr/"} title={"https://www.apec.fr/"} target={"_blank"}>
          <button className={"btn-social--share btn-social--share--apec"}>Apec</button>
        </a>
      
        <Spaces n={0}/>
      
        <a href={"https://www.pole-emploi.fr/"} title={"https://www.pole-emploi.fr/"} target={"_blank"}>
          <button className={"btn-social--share btn-social--share--poleemploi"}>Pôle-emploi</button>
        </a>
      
        <a href={"https://www.regionsjob.com/"} title={"https://www.regionsjob.com/"} target={"_blank"}>
          <button className={"btn-social--share btn-social--share--regionsjob"}>Régions job</button>
        </a>
      </div>
    </article>
  
  </>);
}