export default (args: {updateFetchedData: Function, fetchedData: any}) => (columnId: string) => {
  const newColumns = [...args.fetchedData.missionProfile?.customKanbanView].filter((item: any) => (item._id !== columnId));
  args.updateFetchedData()({
    target: {
      type: "custom", name: "missionProfile", value: {
        ...args.fetchedData.missionProfile,
        customKanbanView: newColumns,
      }
    }
  });
}