
export {getCandidateListeningToMarket};

function getCandidateListeningToMarket(isListeningToMarket: boolean, dateOfListeningToMarket: any) {
  
  if(!dateOfListeningToMarket) {
    return "";
  }
  
  if(!isListeningToMarket) {
    return "";
  }
  
  const dateOfListeningTomarketTime = Math.floor(new Date(dateOfListeningToMarket).getTime() / 1000);
  
  const now = Math.floor((new Date().getTime()) / 1000);
  
  const delta = now - dateOfListeningTomarketTime;
  
  // He was listening in the past, for less than a month ago
  if(now - dateOfListeningTomarketTime < 30 * 24 * 60 * 60 && now - dateOfListeningTomarketTime > 0) {
    return "pool-template__candidate__avatar__available";
  }
  
  // He will be listening in 2 months from now
  if(dateOfListeningTomarketTime - now < 60 * 24 * 60 * 60 && dateOfListeningTomarketTime - now > 0) {
    return "pool-template__candidate__avatar__available";
  }
  
  return "";
}