
import React, {useContext, useEffect} from "react";
import AppContext, {IAppContext, IAppV2Context} from "../../componentsApp/context/AppContext";
import SubPageLayout from "../../sharedComponents/layouts/SubPageLayout";
import PageLayout from "../../sharedComponents/layouts/PageLayout";
import {abstractGetFetch, abstractPostFetch, abstractPostFileFetch, abstractPutFetch} from "../../../utils/abstractFetch";
import Spaces from "../../sharedComponents/components/Spaces";
import CloseButton from "../../sharedComponents/components/Buttons/CloseButton";
import ButtonEdit from "../../sharedComponents/components/Buttons/ButtonEdit";
import PageLayoutWithHeader from "../../sharedComponents/layouts/PageLayoutWithHeader";
import {RecruiterProfilesUserAccount} from "../componentsRecruiterProfiles/componentsRPUser/RecruiterProfilesUserAccount";
import {RecruiterParametersPublicity} from "./componentsRecruiterParametersParameters/RecruiterParametersPublicity";
import {RecruiterParametersOptions} from "./componentsRecruiterParametersParameters/RecruiterParametersOptions";
import {RecruiterParametersPersonnal} from "./componentsRecruiterParametersParameters/RecruiterParametersPersonnal";
import {useAssistant} from "../../../utils/useAssistant/useAssistant";
import axios from "axios";
import {devLog} from "../../../utils/devLog/devLog";
import {notification} from "../../../utils/notification";
import {RecruiterParametersTokenForLinkedin} from "./componentsRecruiterParametersParameters/RecruiterParametersTokenForLinkedin";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  /** Assistant for fetching parameters */
  const [parameters, statusGet, updateDataGet, assistantGetParameters] = useAssistant();
  
  /** Fetch parameters */
  useEffect(() => {
    (async() => {
      try {
        assistantGetParameters.fetching();
        const fetched = (await axios.get(`${process.env.REACT_APP_API_URL}/ent/b/get/my/business/profile`, {withCredentials: true})).data;
        if(fetched.status === "ok") {
          assistantGetParameters.fetched();
          assistantGetParameters.setForm(fetched.data);
        } else {
          assistantGetParameters.error();
        }
      } catch (error) {
        devLog(error);
        assistantGetParameters.error();
      }
    })();
  }, []);
  
  devLog(parameters);
  
  const updateSingletonParameters = async(nameToUpdateFront: string, nameToUpdateBack: string, newValue: any) => {
    try {
      notification(appContextV2.dispatchPanels, "Envoi de la mise à jour...", "information");
      updateDataGet()({target: {type: "custom", name: nameToUpdateFront, value: newValue}});
      const fetched = (await axios.put(`${process.env.REACT_APP_API_URL}/ent/b/update/business`, {
        [nameToUpdateBack]: newValue,
      }, {withCredentials: true})).data;
      if(fetched.status === "ok") {
        notification(appContextV2.dispatchPanels, "Donnée mise à jour avec succès", "success");
      } else {
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
    } catch(error) {
      devLog(error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  const updateMyLinkedinAddonToken = async() => {
    try {
      notification(appContextV2.dispatchPanels, "Obtention du code...", "information");
      
      const fetched = (await axios.put(`${process.env.REACT_APP_API_URL}/ent/b/addonlinkedintoken`, {}, {withCredentials: true}));
      
      if(fetched.data.status !== "ok") return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      
      notification(appContextV2.dispatchPanels, "Code obtenu", "success");
  
      updateDataGet()({target: {type: "custom", name: "tokenForLinkedinAddon", value: fetched.data.data}});
      
    } catch(error) {
      devLog(error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  return(<SubPageLayout status={statusGet} subPage={props.subPage}>
  
    {/*<Spaces n={2}/>*/}
    
    {
      appContextV2.appSession.session.userRole === "admin" && <>
        <RecruiterParametersPublicity assistantGet={assistantGetParameters} parameters={parameters} updateSingleton={updateSingletonParameters} />

        <Spaces n={1}/>
  
        <RecruiterParametersOptions assistantGet={assistantGetParameters} parameters={parameters} updateSingleton={updateSingletonParameters} />

        <Spaces n={1}/>
      </>
    }
    
    <RecruiterParametersTokenForLinkedin assistantGet={assistantGetParameters} parameters={parameters} updateMyLinkedinAddonToken={updateMyLinkedinAddonToken} />
  
    <Spaces n={1}/>
  
    <RecruiterParametersPersonnal assistantGet={assistantGetParameters} parameters={parameters} updateSingleton={updateSingletonParameters} />

  </SubPageLayout>);
}
