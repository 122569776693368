import React, {useContext, useEffect, useState} from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import Spaces from "../../../sharedComponents/components/Spaces";
import {ButtonBack} from "../../../sharedComponents/components/Buttons/ButtonBack";

export default (props: IAssistantContext) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<section>
    
    <Spaces n={0}/>
    
    <div className={"assistant--v2-container--no-flex"}>
      <div className={"space-light"}>
        <ButtonBack back={() => props.wizard.previousStep()}/>
      </div>
    </div>
    
    <div className={"assistant--v2-title"}>
      Le CV est-il obligatoire ou facultatif ?
    </div>
    
    <Spaces n={1}/>
    
    <div className={"assistant--v2-container"}>
      
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "cvMandatory", value: true}});
                props.wizard.goToStep('remote');
              }}>
        CV obligatoire
      </button>
      
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "cvMandatory", value: false}});
                props.wizard.goToStep('remote');
              }}>
        CV facultatif
      </button>
    
    </div>
    
    <Spaces n={1}/>
    
    <div className={"text--center text--grey assistant--v2-container--no-flex"}>
      Si le CV est obligatoire, le candidat devra en poster un avant de répondre au questionnaire.
    </div>
  
  </section>);
}