import React, {useContext} from "react";
import {format} from "date-fns";
import undefinedHelmetString from "../../../utils/undefinedHelmetString";
import Spaces from "../../sharedComponents/components/Spaces";
import {devLog} from "../../../utils/devLog/devLog";
import {notification} from "../../../utils/notification";
import AppContext, {IAppV2Context} from "../../componentsApp/context/AppContext";
import axios from "axios";
import {Link} from "react-router-dom";

export {RecruiterPoolsPoolV2};

const RecruiterPoolsPoolV2 = (props: {
  
  item: any,
  pinFront: Function,
  deleteFront: Function,
  updateFront: Function,
  updatePrivacyFront: Function,
  isResearchActive: boolean,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const pin = async() => {
    try {
      
      // Update front
      props.pinFront(props.item._id, !props.item.pinned);
      
      // Update back
      const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/pool/m/pin`, {
        poolId: props.item._id,
        value: !props.item.pinned,
      }, {withCredentials: true});
      
      if(fetched.data.status !== "ok") {
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      notification(appContextV2.dispatchPanels, "Registre des épingles mis à jour", "success");
      
    } catch (error) {
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      devLog(error);
    }
  }
  
  return (<article className={`pool--v2 ${props.isResearchActive && "pool--v2--active-research"}`}>
    
    {props.item.private && <img src={"https://static.myshortlist.co/btn-lock--1.svg"} className={"pool--v2__lock"} title={"Cette communauté est privée"}/>}
    
    <div
      className={`pool--v2__avatar ${props.item.color === "green" && "pool--v2__avatar--green"} ${props.item.color === "blue" && "pool--v2__avatar--blue"} ${props.item.color === "yellow" && "pool--v2__avatar--yellow"} ${props.item.color === "orange" && "pool--v2__avatar--orange"} ${props.item.color === "pink" && "pool--v2__avatar--pink"}`}
      title={"nombre de candidats dans cette communauté"}>
      {props.item?.number || 0}
    </div>
    
    <div className={"pool--v2__title"}>{props.item?.name}</div>
    
    <div className={"pool--v2__date"}
         title={"date de la dernière action"}><span className={"text--minified2x"}>Dernière action: {undefinedHelmetString(() => format(new Date(props.item?.dateOfCreation), "dd-MM-yyyy"))}</span></div>
    
    <div className={"pool--v2__buttons"}>
      <button className={"pool--v2__buttons__parameters"} onClick={() => {
        appContextV2.dispatchPanels({
          type: "OPEN_WINDOW", value: {
            type: "poolOptions",
            data: {
              item: props.item,
              deleteFront: props.deleteFront,
              updateFront: props.updateFront,
              updatePrivacyFront: props.updatePrivacyFront,
            }
          }
        })
      }}>
        <img src={"https://static.myshortlist.co/btn-parameters.svg"}/>
      </button>
      <Link to={`/business/community/${props.item._id}`}>
        <button className={"pool--v2__buttons__main"}>
          Entrer
        </button>
      </Link>
      <button className={`pool--v2__buttons__pinned ${props.item.pinned ? "pool--v2__buttons__pinned--active" : ""}`} onClick={() => pin()}>
        <img src={"https://static.myshortlist.co/btn-pin.svg"}/>
      </button>
    </div>
    
    <div>
      <Spaces n={0}/>
    </div>
  
  </article>);
}