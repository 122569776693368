import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../componentsApp/context/AppContext";

export {RecruiterPoolsFilters};

const RecruiterPoolsFilters = (props: {
  createdBy: string,
  name: string,
  olderThan: number|string,
  earlierThan: number|string,
  setCreatedBy: Function,
  setName: Function,
  setOlderThan: Function,
  setEarlierThan: Function,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return(<section className={"filters"}>
    
    {/*<article className={"filters__element"}>*/}
    {/*  <div className={"filters__element__title"}>*/}
    {/*    Créée par*/}
    {/*  </div>*/}
    {/*  <div className={"filters__element__content"}>*/}
    {/*    <input type={"text"} className={"filters__element__input"} value={props.createdBy} name={"createdBy"} onChange={(e) => props.setCreatedBy(e.target.value)} />*/}
    {/*  </div>*/}
    {/*</article>*/}
    
    <article className={"filters__element"}>
      <div className={"filters__element__title"}>
        Nom de la communauté
      </div>
      <div className={"filters__element__content"}>
        <input type={"text"} className={"filters__element__input"} value={props.name} name={"name"} onChange={(e) => props.setName(e.target.value)} />
      </div>
    </article>
    
    <article className={"filters__element"}>
      <div className={"filters__element__title"} title={"Dernière action depuis moins de ... (en jours)"}>
        Pas d'action depuis (jours)
      </div>
      <div className={"filters__element__content"}>
        <input type={"number"} className={"filters__element__input"} value={props.olderThan} name={"before"} onChange={(e) => props.setOlderThan(e.target.value)} />
      </div>
    </article>
    
    <article className={"filters__element"}>
      <div className={"filters__element__title"} title={"Dernière action depuis plus de ... (en jours)"}>
        Dernière action depuis (jours)
      </div>
      <div className={"filters__element__content"}>
        <input type={"number"} className={"filters__element__input"} name={"after"} value={props.earlierThan} onChange={(e) => props.setEarlierThan(e.target.value)} />
      </div>
    </article>
    
    {/*<article title={appContextV2.appSession.session.userRole === "admin" ? "" : "Seuls les admin peuvent consulter les communautés d'autres équipes"}*/}
    {/*         className={`filters__element ${appContextV2.appSession.session.userRole === "admin" ? "" : "filters__element--disabled"}`}>*/}
    {/*  <div className={"filters__element__title"}>*/}
    {/*    Équipe*/}
    {/*  </div>*/}
    {/*  <div className={"filters__element__content"}>*/}
    
    {/*  </div>*/}
    {/*</article>*/}
    
  </section>);
}
