import {notification} from "../../../../../../utils/notification";

export default (args: {fetchedData: any, updateFetchedData: Function, dispatchPanels: Function}) => () => {
  if (args.fetchedData.missionProfile?.customKanbanView.length >= 8) {
    return notification(args.dispatchPanels, "Le nombre maximum de colonnes est atteint", "warning");
  }
  return args.updateFetchedData()({
    target: {
      type: "custom", name: "missionProfile", value: {
        ...args.fetchedData.missionProfile,
        customKanbanView: [...args.fetchedData.missionProfile?.customKanbanView, {
          activateForm: false,
          activateMail: false,
          activatePool: false,
          description: "",
          mailContent: "",
          mailDelay: 0,
          mailSubject: "",
          name: "Nouvelle colonne",
          poolId: "",
          _id: "",
        }],
      }
    }
  });
}