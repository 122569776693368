import React from "react";
import {shortenText} from "../../../../utils/shortenText";
import CloseButton from "../../../sharedComponents/components/Buttons/CloseButton";
import {isQuestionReady} from "../../componentsRecruiterEditor/FormItem";
import EditorQuestionAnswers from "./EditorQuestionAnswers";

export default (props: any) => {
  
  return (<article className={`editor-v2__section__question cursor--pointer ${props.item.extended ? 'editor-v2__section__question--extended' : ''}`}
                   draggable={true}
                   onDragOver={(e) => {e.preventDefault();}}
                   onDrop={() => {props.drop(props.id, props.index)}}
                   onDragStart={(e) => {
                     e.dataTransfer.setData('text/plain', '');
                     props.drag(props.id, props.index);
                   }}
  >
    <div
      title={isQuestionReady(props.item) ? "La question est prête" : "La question n'est pas prête, elle ne sera pas présentée au candidat"}
      className={`editor-v2__section__question__sticker ${isQuestionReady(props.item) ? "editor-v2__section__question__sticker--blue" : "editor-v2__section__question__sticker--orange"}`}>
      {props.index + 1}
    </div>
    <div className={"editor-v2__section__question__content"}>
      <div className={"editor-v2__section__question__content__header"}>
        <div className={"space-flex--left"}>
          <div>
            <img className={"img-type"} src={getImgKind(props.item.type)}/>
          </div>
          <button className={"btn--hollow"} onClick={() => {
            props.toggleExtended(props.id, props.index);
          }}>
            <img className={`text--hollow arrow-btn ${props.item.extended ? "arrow-btn--activated" : ""}`}
                 src={"https://static.myshortlist.co/btn-arrow-list.svg"}
                 alt={"toggle"}/>
          </button>
          <span className={`${props.item.extended ? "text--grey" : ""}`}>{shortenText(props.item.question, 65)}</span>
        </div>
        <div className={"space-flex--right"}>
          <CloseButton title={"Supprimer la question"} eventOnClick={() => props.deleteQuestion(props.id, props.index)}/>
        </div>
      </div>
      {
        props.item.extended && <div className={"editor-v2__section__question__content__extended entire-width animation-fade-in"} draggable={true}>
          <div className={"text--magnified2x editor-v2__section__question__titles"}>Question</div>
          <textarea value={props.item.question} className={"editor-v2__section__question__textarea"}
                    onChange={(e) => {
                      props.updateQuestion(props.id, props.index, {"question": e.target.value})
                    }}
                    onDragStart={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
          >
          </textarea>
          <EditorQuestionAnswers {...props} />
        </div>
      }
    </div>
  </article>);
  
}

function getImgKind(kind: 'freeText' | 'singleChoice' | 'multipleChoice') {
  switch(kind) {
    case 'freeText':
      return "https://static.myshortlist.co/btn-text.svg";
    case 'singleChoice':
      return "https://static.myshortlist.co/btn-radio.svg";
    case 'multipleChoice':
      return "https://static.myshortlist.co/btn-checkbox.svg";
  }
}