import React, {useEffect, useState} from "react";
import Spaces from "../../../Spaces";
import LoadingStyle2 from "../../../Loading/LoadingStyle2";
import {fetchPools} from "../../../../../recruitersPages/componentsRecruiterPools/dependenciesRecruiterPools/fetch/fetchPools";
import {devLog} from "../../../../../../utils/devLog/devLog";

export {WCOCommunity};

const WCOCommunity = (props: {
  white: boolean,
  data: any,
  updateData: Function,
  isPageLoading: boolean,
  updateColumnFront: Function,
  saveColumnData: Function,
}) => {
  
  /** Downloaded pools */
  const [fetchingCommunities, setFetchingCommunities] = useState("asleep");
  const [communities, setCommunities]: [any, any] = useState([]);
  useEffect(() => {
    fetchPools(setFetchingCommunities, setCommunities);
  }, []);
  devLog("communities", communities);
  
  return(<>
  
    <article className={`white-container ${!props.white ? "white-container--grey" : ""}`}>
      <div className={"space-flex--space-between"}>
        <span className={"text--bold text--magnified11x"}>Activer l'action "Déplacer dans une communauté" ?</span>
        <div>
          <label className={"switch-V2"}>
            <input type={"checkbox"} className={"checkbox-on-off"} checked={!!props.data.activatePool} onChange={() => {
              if(!!props.data.activatePool) {
                // Turn off
                // updateFronts
                props.updateColumnFront(props.data._id, {activatePool: false});
                props.updateData()({target: {type: "custom", name: "activatePool", value: false}});
                // update back
                props.saveColumnData(props.data._id, {...props.data, activatePool: false});
              }
              if(!props.data.activatePool) {
                // Turn on
                // updateFronts
                props.updateColumnFront(props.data._id, {activatePool: true});
                props.updateData()({target: {type: "custom", name: "activatePool", value: true}});
              }
            }}/>
            <span className="slider"> </span>
          </label>
        </div>
      </div>
    
      {/*------------------- Content*/}
      {
        !!props.data.activatePool && <div className={"text--center"}>
          {
            (fetchingCommunities === "fetched" && communities.length !== 0) && <>
              <Spaces n={1}/>
              <select className={"input--v2"} value={props.data.poolId} name={"poolId"} onChange={props.updateData()}>
                <option value={""} disabled={true}>-- Sélectionne une communauté --</option>
                {communities.map((item: any, key: number) => (<option key={key} value={item._id}>
                  {item.name} ({item.number})
                </option>))}
              </select>
              <button disabled={props.data.name.length === 0 || props.isPageLoading} className={"btn-secondary"} onClick={() => {
                // Update front
                props.updateColumnFront(props.data._id, {
                  activatePool: props.data.activatePool,
                  poolId: props.data.poolId,
                });
                // Update back
                props.saveColumnData(props.data._id, props.data);
              }}>
                Enregistrer
              </button>
            </>
          }
          {
            (fetchingCommunities === "fetched" && communities.length === 0) && <>
              <Spaces n={1}/>
              Aucune communauté disponible
            </>
          }
          {
            (fetchingCommunities === "fetching") && <>
              <Spaces n={1}/>
              <LoadingStyle2 />
            </>
          }
        </div>
      }
      
    </article>
  
  </>);
}