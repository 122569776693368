
import React from "react";
import PageLayout from "../layouts/PageLayout";
import Spaces from "../components/Spaces";
import ButtonWrapper from "../layouts/ButtonWrapper";
import RatingComponent from "../components/RatingComponent";
import AppContext from "../../componentsApp/context/AppContext";
import {abstractPostFetch} from "../../../utils/abstractFetch";

export default class extends React.Component<any, any> {
  
  static contextType = AppContext;
  
  constructor(props: any) {
    super(props);
  
    const token: string = this.props.match.params.token;
    
    this.state = {
      pageStatus: "pending",
      reviewStatus: "ok", // ok / sent
      buttonStatus: "ready",
      token: token,
      enjoyed: 0,
      better: 0,
      fastness: 0,
      questions: 0,
      comment: "",
    };
  }
  
  componentDidMount(): void {
    this.setState({pageStatus: "ok"});
  }
  
  updateGrade = (name: string, grade: string) => {
    const newEvaluation: any = {...this.state};
    newEvaluation[name] = grade;
    this.setState((previousState: any) => ({
      ...newEvaluation
    }));
  };
  
  postReview = async () => {
    try {
  
      this.setState({buttonStatus: "pending"});
      
      // const fetched: any = await abstractPostFetch("/rev/candidate", {data: this.state});
  
      this.setState({buttonStatus: "ok", reviewStatus: "sent"});
      
      // if(fetched.status === "ok") {
      //   this.setState({buttonStatus: "ok", reviewStatus: "sent"});
      // } else {
      //   this.setState({buttonStatus: "error", reviewStatus: "sent"});
      // }
      
    } catch(error) {
      console.log(error);
      this.setState({buttonStatus: "error"});
    }
  }
  
  render() {
  
    const language: string = this.context.appState.language;
    const isButtonReady: boolean = this.state.buttonStatus === "ready" &&
      (!!this.state.enjoyed && !!this.state.better && !!this.state.fastness && !!this.state.questions );
  
    return(<>
      <PageLayout page={this.props.page} status={this.state.pageStatus}>
  
        <Spaces n={3}/>
        
        {this.state.reviewStatus === "sent" && <p className={"text--center text--magnified2x"}>
            Merci pour votre retour d'expérience ! vous pouvez maintenant fermer cette page.
          </p>}
  
        {this.state.reviewStatus === "ok" && <>

          <h1 className={"title--bold text--center"}>Donnez-nous votre avis sur cette manière de postuler</h1>

          <p className={"text--center text--hollow"}>
            Ce sondage est facultatif et anonyme. Aucun résultat nomminatif n'est communiqué.
          </p>

          <Spaces n={2}/>

          <table className={"apply__sumup__table container--small"} >
            <tbody>
            <tr>
              <td>
                Avez-vous apprécié cette manière de postuler ?
              </td>
              <td>
                <RatingComponent name={"enjoyed"} grade={this.state.enjoyed}
                                 updateFunction={this.updateGrade} />
              </td>
            </tr>
            <tr>
              <td>
                Préférez-vous cette manière de postuler ?
              </td>
              <td>
                <RatingComponent name={"better"} grade={this.state.better}
                                 updateFunction={this.updateGrade} />
              </td>
            </tr>
            <tr>
              <td>
                Le processus était-il assez rapide ?
              </td>
              <td>
                <RatingComponent name={"fastness"} grade={this.state.fastness}
                                 updateFunction={this.updateGrade} />
              </td>
            </tr>
            <tr>
              <td>
                Les questions étaient-elles pertinentes ?
              </td>
              <td>
                <RatingComponent name={"questions"} grade={this.state.questions}
                                 updateFunction={this.updateGrade} />
              </td>
            </tr>
            <tr>
              <td>
                Avez-vous une remarque à nous communiquer ?
              </td>
              <td>
                <input type={"text"} className={"modern-input"} />
              </td>
            </tr>
            </tbody>
          </table>

          <Spaces n={3}/>

          <p className={"text--center"}>
            <button disabled={!isButtonReady} className={"btn-main"} onClick={()=>{this.postReview()}}>
              <ButtonWrapper buttonStatus={this.state.buttonStatus}>
                { language === 'FR' && <>Envoyer</> }
                { language !== 'FR' && <>Send</> }
              </ButtonWrapper>
            </button>
          </p>
        </>}
        
      </PageLayout>
    </>);
  }
}