
export default (displayedMissions: any, page: number, resultsPerPage: number, numberOfPages: number, setMissions: Function, setPage: Function) => (missionId: string) => {
  
  const willLastPageBeBlank: boolean = (displayedMissions.length - 1) % resultsPerPage === 0;
  const isCurrentPageLast: boolean = page === numberOfPages;
  
  setMissions((ps: any) => [...ps.filter((item: any) => (item.missionId !== missionId))]);
  
  if(willLastPageBeBlank && isCurrentPageLast) {
    setPage((ps: number) => ps - 1);
  }
}