import React from "react";
import Spaces from "../../components/Spaces";
import {CASAvailable} from "./componentsCandidateActionStrategy/CASAvailable";
import {CASUnavailable} from "./componentsCandidateActionStrategy/CASUnavailable";
import {CASJobWithdraw} from "./componentsCandidateActionStrategy/CASJobWithdraw";
import {CASCommunityWithdraw} from "./componentsCandidateActionStrategy/CASCommunityWithdraw";

export {CandidateActionStrategy};

const CandidateActionStrategy = (props: any) => {
  
  switch(props.action) {
    
    case 'available':
      return(<CASAvailable {...props}/>);
      
    case 'unavailable':
      return(<CASUnavailable {...props}/>);
      
    case 'job-withdraw':
      return(<CASJobWithdraw {...props}/>);
      
    case 'community-withdraw':
      return(<CASCommunityWithdraw {...props}/>);
      
    default:
      return (<>
        <Spaces n={2}/>
        <div className={"text--magnified11x text--center"}>Le lien ne semble pas valide</div>
        <Spaces n={1}/>
      </>);
  }
  
}