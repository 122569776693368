import AppContext, {IAppContext, IAppV2Context} from "../../../../componentsApp/context/AppContext";
import React, {useContext, useState} from "react";
import WindowWrapper from "../../../layouts/WindowLayout";
import Spaces from "../../Spaces";
import {abstractPostFetch, abstractPutFetch} from "../../../../../utils/abstractFetch";
import ButtonWrapper from "../../../layouts/ButtonWrapper";
import {capitalizeMulti} from "../../../../../utils/converters";
import {useForm} from "use-formidable";
import {devLog} from "../../../../../utils/devLog/devLog";
import {notification} from "../../../../../utils/notification";
import axios from "axios";
import {WKAAMail} from "./componentsWKAA/WKAAMail";
import {WKAASms} from "./componentsWKAA/WKAASms";
import {WKAAButtons} from "./componentsWKAA/WKAAButtons";

export default function(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const candidateData = appContextV2.appPanels.window.windowOptions.data.candidateData;
  
  const initialColumnData = {...appContextV2.appPanels.window.windowOptions.data.columnData};
  const [columnData, updateColumnData, formidableData] = useForm(initialColumnData);
  
  const [isPageLoading, setIsPageLoading] = useState(false);
  
  const isEmailActionSet = initialColumnData.activateMail && initialColumnData.mailSubject && initialColumnData.mailContent;
  const isSmsActionSet = initialColumnData.activateSms && initialColumnData.smsText;
  
  const moveCandidacyFront = appContextV2.appPanels.window.windowOptions.data.moveCandidacyFront;
  const toColumnId = appContextV2.appPanels.window.windowOptions.data.toColumnId;
  const fromColumnId = appContextV2.appPanels.window.windowOptions.data.fromColumnId;
  const setIsLoading = appContextV2.appPanels.window.windowOptions.data.setIsLoading;
  
  return (<WindowWrapper size={"big"} status={"ok"} windowTitle={""}>
    <div className={"container"}>
      
      <div>Tu es sur le point d'envoyer {isEmailActionSet ? "un email" : ""} {isEmailActionSet && isSmsActionSet ? "et" : ""} {isSmsActionSet ? "un SMS" : ""} à ce candidat.</div>
      
      <Spaces n={0}/>
      
      <WKAAButtons columnData={columnData}
                   setIsLoading={setIsLoading}
                   candidateData={candidateData}
                   isPageLoading={isPageLoading}
                   moveCandidacyFront={moveCandidacyFront}
                   setIsPageLoading={setIsPageLoading}
                   toColumnId={toColumnId}
                   fromColumnId={fromColumnId}/>
      
      <Spaces n={1}/>
      
      {
        isEmailActionSet && <WKAAMail columnData={columnData}
                                      updateColumnData={updateColumnData}
                                      white={props.white} />
      }
      
      <Spaces n={0}/>
      
      {
        isSmsActionSet && <WKAASms columnData={columnData}
                                   updateColumnData={updateColumnData}
                                   white={props.white} />
      }
  
      <Spaces n={0}/>
      
      <div className={"text--grey"}>* Si vous supprimez des champs, l'action concernée ne s'effectuera pas</div>
      
    </div>
  </WindowWrapper>);
}
