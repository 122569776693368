import React, {useContext, useEffect, useState} from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import Spaces from "../../../sharedComponents/components/Spaces";
import {ButtonBack} from "../../../sharedComponents/components/Buttons/ButtonBack";

export default (props: IAssistantContext) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<section>
    
    <Spaces n={0}/>
    
    <div className={"assistant--v2-container--no-flex"}>
      <div className={"space-light"}>
        <ButtonBack back={() => props.wizard.previousStep()}/>
      </div>
    </div>
    
    <div className={"assistant--v2-title"}>
      Le lieu du poste est-il une adresse fixe ?
    </div>
    
    <Spaces n={1}/>
    
    <div className={"assistant--v2-container"}>
      
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "roaming", value: true}});
                props.wizard.goToStep('useSavedLocation');
              }}>
        Oui, le poste est à une adresse fixe
      </button>
      
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "roaming", value: false}});
                props.wizard.goToStep('roamingPlace');
              }}>
        Non, le poste est sur plusieurs adresses ou en itinérance
      </button>
    
    </div>
    
    <Spaces n={1}/>
    
    <div className={"text--center text--grey assistant--v2-container--no-flex"}>
    </div>
  
  </section>);
}