import React, {useContext, useEffect, useState} from "react";
import PageLayoutWithHeader from "../sharedComponents/layouts/PageLayoutWithHeader";
import Spaces from "../sharedComponents/components/Spaces";
import {ButtonBigPlusV2} from "../sharedComponents/components/Buttons/ButtonBigPlus";
import AppContext, {IAppV2Context} from "../componentsApp/context/AppContext";
import {RecruiterPoolsPoolV2} from "./componentsRecruiterPools/RecruiterPoolsPoolV2";
import {devLog} from "../../utils/devLog/devLog";
import {PagesComponent} from "../sharedComponents/components/PagesComponent";
import {fetchAddPool} from "./componentsRecruiterPools/dependenciesRecruiterPools/fetch/fetchAddPool";
import {fetchPools} from "./componentsRecruiterPools/dependenciesRecruiterPools/fetch/fetchPools";
import {RecruiterPoolsFilters} from "./componentsRecruiterPools/RecruiterPoolsFilters";
import {RecruiterPoolsEmpty} from "./componentsRecruiterPools/RecruiterPoolsEmpty";
import applyFiltersPools from "./componentsRecruiterPools/dependenciesRecruiterPools/applyFiltersPools";
import getNumberOfPages from "../../utils/getNumberOfPages";
import poolsUpdatePrivacyFront from "./componentsRecruiterPools/dependenciesRecruiterPools/frontUpdates/poolsUpdatePrivacyFront";
import poolsPinFront from "./componentsRecruiterPools/dependenciesRecruiterPools/frontUpdates/poolsPinFront";
import poolsDeleteFront from "./componentsRecruiterPools/dependenciesRecruiterPools/frontUpdates/poolsDeleteFront";
import poolsUpdateFront from "./componentsRecruiterPools/dependenciesRecruiterPools/frontUpdates/poolsUpdateFront";
import RecruiterPoolsNoResult from "./componentsRecruiterPools/RecruiterPoolsNoResult";

/**
 * --------------------------------------
 * Displays and manage pools (communities)
 * Primary-level page
 * --------------------------------------
 */
export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  /** Downloaded pools for a specific team and fetch status */
  const [pools, setPools]: [any, any] = useState([]);
  const [fetchStatusPools, setFetchStatusPools]: [any, any] = useState("asleep");
  
  /** Downloaded free candidacies and statuses */
  // const [freeCandidacies, setFreeCandidacies]: [any, any] = useState([]);
  // const [fetchStatusFreeCandidacies, setFetchStatusFreeCandidacies]: [any, any] = useState("asleep");
  
  /** Displayed pools according to filters */
  const [displayedPools, setDisplayedPools] = useState([]);
  const [createdBy, setCreatedBy] = useState("");
  const [name, setName] = useState("");
  const [olderThan, setOlderThan] = useState("");
  const [earlierThan, setEarlierThan] = useState("");
  
  /** Other utilities */
  const [addingPool, setAddingPool] = useState(false);
  
  /** Handle pages */
  const [page, setPage] = useState(1);
  const resultsPerPage = 8;
  const numberOfPages: number = getNumberOfPages(displayedPools.length, resultsPerPage);
  
  /** Initial pools-fetch (of the current team only) */
  useEffect(() => {fetchPools(setFetchStatusPools, setPools);}, []);
  devLog(pools);
  
  /** Display pools according to various filters */
  useEffect(() => {applyFiltersPools(pools, setDisplayedPools, createdBy, name, olderThan, earlierThan);},
    [pools, createdBy, name, earlierThan, olderThan]);
  const isResearchActive: boolean = (!!createdBy || !!name || !!olderThan || !!earlierThan)
  
  /** Front update */
  const pinFront = poolsPinFront(setPools);
  const updatePrivacyFront = poolsUpdatePrivacyFront(setPools);
  const deleteFront = poolsDeleteFront(displayedPools, page, resultsPerPage, numberOfPages, setPools, setPage);
  const updateFront = poolsUpdateFront(setPools);
  
  /** Adds a new community */
  const addCommunity = fetchAddPool(pools, setPools, setAddingPool, appContextV2.dispatchPanels);
  
  return (<PageLayoutWithHeader grey={true} title={"Communautés"} small={true} fullWidth={true} page={props.page} status={fetchStatusPools}>
    
    {/*------------------------------------------------------------------ Free candidacies */}
    
    {/*------------------------------------------------------------------ Filters */}
    {pools?.length !== 0 && <RecruiterPoolsFilters setCreatedBy={setCreatedBy}
                                                   createdBy={createdBy}
                                                   name={name}
                                                   earlierThan={earlierThan}
                                                   olderThan={olderThan}
                                                   setName={setName}
                                                   setOlderThan={setOlderThan}
                                                   setEarlierThan={setEarlierThan}/>}
  
    {/*------------------------------------------------------------------ Text if there are no community in the team */}
    {pools?.length === 0 && <RecruiterPoolsEmpty/>}
  
    {/*--------------------------------------------------------------------- Research gave no result*/}
    {(pools.length !== 0 && displayedPools?.length === 0) && <RecruiterPoolsNoResult />}
    
    {/*------------------------------------------------------------------ Communities */}
    {(pools.length !== 0 && displayedPools?.length !== 0) && <section className={"pools--v2"}>
      {displayedPools
      .slice((page * resultsPerPage - resultsPerPage), (page * resultsPerPage))
      .map((item: any, key: number) => (<RecruiterPoolsPoolV2 item={item}
                                                              pinFront={pinFront}
                                                              key={key}
                                                              isResearchActive={isResearchActive}
                                                              deleteFront={deleteFront}
                                                              updateFront={updateFront}
                                                              updatePrivacyFront={updatePrivacyFront}/>))}
    </section>}
    
    <Spaces n={2}/>
    
    {/*------------------------------------------------------------------ Button to add a pool*/}
    <div className={"space-flex--center"}>
      {pools.length < 50 && <ButtonBigPlusV2 disabled={addingPool}
                                             cb={async() => addCommunity()}
                                             title={"Ajoute une communauté"}/>}
    </div>
    
    <Spaces n={1}/>
    
    {/*------------------------------------------------------ Pages*/}
    <div className={"text--center"}>
      <PagesComponent page={page} pages={numberOfPages} setPage={setPage}/>
    </div>
  
  </PageLayoutWithHeader>);
}