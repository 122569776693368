export default (args: {updateFetchedData: Function, fetchedData: any }) => (columnId: string, keyValuePair: any) => {
  const index: number = [...args.fetchedData.missionProfile.customKanbanView].map((item: any) => (item._id)).indexOf(columnId);
  const newColumns = [...args.fetchedData.missionProfile.customKanbanView];
  newColumns[index] = {
    ...newColumns[index],
    ...keyValuePair
  }
  args.updateFetchedData()({
    target: {
      type: "custom", name: "missionProfile", value: {
        ...args.fetchedData.missionProfile,
        customKanbanView: newColumns,
      }
    }
  });
}