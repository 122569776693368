import React, {useContext, useEffect, useState} from "react";
import {devLog} from "../../../../utils/devLog/devLog";
import AppContext, {IAppV2Context} from "../../../componentsApp/context/AppContext";
import SubPageLayout from "../../../sharedComponents/layouts/SubPageLayout";
import Spaces from "../../../sharedComponents/components/Spaces";
import {NotOnSmartphones} from "../../../sharedComponents/components/NotOnSmartphones";
import {capitalizeMulti} from "../../../../utils/converters";
import PoolPeopleNav from "./componentsRecruiterPoolPeople/PoolPeopleNav";
import PoolPeopleResearch from "./componentsRecruiterPoolPeople/PoolPeopleResearch";
import {useForm} from "use-formidable";
import axios from "axios";
import {notification} from "../../../../utils/notification";
import {shortenText} from "../../../../utils/shortenText";
import {format} from "date-fns";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import {getCandidateColor} from "./dependenciesRecruiterPoolPeople/getCandidateColor";
import {getCandidateListeningToMarket} from "./dependenciesRecruiterPoolPeople/getCandidateListeningToMarket";
import {getCandidateAvatarColor} from "./dependenciesRecruiterPoolPeople/getCandidateAvatarColor";
import {fetchCandidatesToPools} from "./dependenciesRecruiterPoolPeople/fetchCandidatesToPools";
import {applyFiltersPoolPeople} from "./dependenciesRecruiterPoolPeople/applyFiltersPoolPeople";
import {saveXlsx} from "./dependenciesRecruiterPoolPeople/saveXlsx";

export default (props: any) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

    /** Fetch candidates,  */
    const [fetchStatus, setFetchStatus]: ["asleep" | "fetching" | "fetched" | "error", Function] = useState("asleep");
    const [candidates, updateCandidates, formidableCandidates] = useForm([]);
    const [displayedCandidates, setDisplayedCandidates]: [any, any] = useState([]);
    const [idsSelectedCandidates, setIdsSelectedCandidates]: [any, any] = useState([]);

    /** Filters */
    const [filterByWords, setFilterByWords] = useState("");
    const [filterByAvailableFrom, setFilterByAvailability] = useState("");
    const [filterByLastExchange, setFilterByLastExchange] = useState("");
    const [showRemovedCandidates, setRemovedCandidates] = useState(false);
    const areFiltersActive = !!filterByWords || !!filterByAvailableFrom || !!filterByLastExchange;

    const resetFilters = () => {
        setFilterByWords("");
        setFilterByAvailability("");
        setFilterByLastExchange("");
        setRemovedCandidates(false);
    }

    const filters = {
        idsSelectedCandidates,
        filterByWords,
        setFilterByWords,
        filterByAvailableFrom,
        setFilterByAvailability,
        filterByLastExchange,
        setFilterByLastExchange,
        showRemovedCandidates,
        setRemovedCandidates,
        resetFilters,
    }

    /** Fetch candidates */
    useEffect(() => {
        fetchCandidatesToPools({
            poolId: props.match.params.poolid,
            setFetchStatus: setFetchStatus,
            formidableCandidates: formidableCandidates,
            appContextV2: appContextV2,
        })
    }, []);
    devLog("candidates", candidates);

    /** Updates displayed list with filters */
    useEffect(() => {
        applyFiltersPoolPeople({
            filterByWords: filterByWords,
            filterByAvailableFrom: filterByAvailableFrom,
            filterByLastExchange: filterByLastExchange,
            showRemovedCandidates: showRemovedCandidates,
            candidates: candidates,
            setDisplayedCandidates: setDisplayedCandidates,
        });
    }, [
        candidates,
        filterByWords,
        filterByAvailableFrom,
        filterByLastExchange,
        showRemovedCandidates]);

    /** Select / deselect handlers */
    const selectAll = () => {
        setIdsSelectedCandidates(displayedCandidates.map((item: any) => (item._id)));
    }
    const unSelectAll = () => {
        setIdsSelectedCandidates([]);
    }
    const toggleSelect = (candidateId: string) => {
        setIdsSelectedCandidates((ps: any) => (ps.includes(candidateId) ? [...ps.filter((item: string) => (item !== candidateId))] : [...ps, candidateId]));
    }
    const selectorHandlers = {selectAll, unSelectAll, toggleSelect};
    const isItemChecked = (candidateId: string) => {
        return idsSelectedCandidates.includes(candidateId);
    }

    /** Update candidates front */
    const updateCandidatesBulk = (ids: string[], keyValuesPairs: any) => {
        let newCandidates = [];
        for (let candidate of candidates) {
            if (!ids.includes(candidate._id)) {
                newCandidates.push(candidate);
            } else {
                newCandidates.push({
                    ...candidate,
                    ...keyValuesPairs,
                });
            }
        }
        formidableCandidates.setForm(newCandidates);
    }
    const reloadCandidates = async () => {
        const poolId = props.match.params.poolid;
        try {
            setFetchStatus("fetching");
            const fetched = (await axios.get(`${process.env.REACT_APP_API_URL}/pool/cm/candidates`, {
                headers: {
                    poolid: poolId
                },
                withCredentials: true
            }));
            if (fetched.data.status !== "ok") {
                return setFetchStatus("error");
            }
            formidableCandidates.setForm(fetched.data.data);
            setFetchStatus("fetched");
        } catch (error) {
            devLog(error);
            setFetchStatus("error");
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
    }
    const addCandidateFront = (item: any) => {
        formidableCandidates.setForm([...candidates, item]);
    }

    return (<SubPageLayout status={fetchStatus} subPage={props.subPage}>

        <NotOnSmartphones/>

        <section className={"pool-template hide-on-smartphones"}>
            <article className={"pool-template__body"}>

                {/*--------------------------------------- Research bar */}
                <PoolPeopleResearch filters={filters}/>

                {/*--------------------------------------- Complementary research bar */}


                {/*--------------------------------------- Candidates */}
                <section className={"pool-template__candidates"}>
                    {displayedCandidates.map((item: any, key: number) => (
                        <article
                            className={`pool-template__candidate ${getCandidateColor(item)} ${areFiltersActive ? "pool-template__candidate__filters-active" : ""}`}
                            key={key} onClick={() => {
                            appContextV2.dispatchPanels({
                                type: "OPEN_WINDOW", value: {
                                    type: "candidateWindowV2",
                                    data: {
                                        keywords: filterByWords,
                                        category: "pool",
                                        candidateId: item._id,
                                        missionId: "",
                                        basicData: {
                                            firstName: item.firstName,
                                            lastName: item.lastName,
                                            avatarUrl: item.avatarUrl,
                                        },
                                        frontUpdatePool: {
                                            reloadCandidates: reloadCandidates,
                                            updateCandidatesBulk: updateCandidatesBulk,
                                            addCandidateFront: addCandidateFront
                                        },
                                        frontUpdateMission: {
                                            updateCandidaciesBulk: () => {},
                                        },
                                    }
                                }
                            })
                        }}>
                            <div
                                className={`pool-template__candidate__avatar ${getCandidateAvatarColor(item.random)} ${getCandidateListeningToMarket(item.isListeningToMarket, item.dateOfListeningToMarket)}`}>
                                {!!item.avatarUrl && <img src={item.avatarUrl}/>}
                                {!item.avatarUrl && <>{(item.firstName || "N")[0] ? (item.firstName || "N")[0].toUpperCase() : "N"}{(item.lastName || "N")[0] ? (item.lastName || "N")[0].toUpperCase() : "N"}</>}
                            </div>
                            <div className={"pool-template__candidate__name"}>
                                {shortenText(capitalizeMulti(item.firstName), 15)}
                            </div>
                            <div className={"pool-template__candidate__checkbox"}>
                                <input type={"checkbox"} checked={isItemChecked(item._id)} onClick={(e) => {
                                    e.stopPropagation();
                                    // toggle selection
                                    selectorHandlers.toggleSelect(item._id);
                                }}/>
                            </div>
                        </article>))}
                </section>

                <Spaces n={0}/>

            </article>
            <article className={"pool-template__nav"}>

                {/*--------------------------------------- Left navbar */}
                <PoolPeopleNav areFiltersActive={areFiltersActive}
                               numberOfCandidates={candidates.length}
                               selected={idsSelectedCandidates}
                               frontUpdatesHandlers={{
                                   reloadCandidates: reloadCandidates,
                                   updateCandidatesBulk: updateCandidatesBulk,
                                   addCandidateFront: addCandidateFront
                               }}
                               numberOfResults={displayedCandidates.length}
                               numberOfSelected={idsSelectedCandidates.length}
                               filters={filters}
                               idsSelectedCandidates={idsSelectedCandidates}
                               saveXlsx={saveXlsx({candidates, idsSelectedCandidates})}
                               selectorHandlers={selectorHandlers}
                />

            </article>
        </section>

    </SubPageLayout>);
}



