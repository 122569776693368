import React, {useContext, useEffect, useState} from "react";
import SubPageLayout from "../../../../layouts/SubPageLayout";
import Spaces from "../../../../components/Spaces";
import {capitalizeSentence} from "../../../../../../utils/converters";
import getContract from "../../../../../../utils/getContract";
import getExperience from "../../../../../../utils/getExperience";
import getStarting from "../../../../../../utils/getStarting";
import getSalary from "../../../../../../utils/getSalary";
import {getLocation} from "../../../../../../utils/getLocation";
import {Link} from "react-router-dom";
import {useForm} from "use-formidable";
import {FileDrop} from "../../../../components/Buttons/FileDrop";
import {notification} from "../../../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../../../componentsApp/context/AppContext";
import {devLog} from "../../../../../../utils/devLog/devLog";
import LoadingStyle2 from "../../../../components/Loading/LoadingStyle2";
import axios from "axios";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  /** Terms */
  const [formTerms, updateTerms, formidableTerms] = useForm({
    areTermsAccepted: false,
    isPolicyAccepted: false,
  });
  
  /** Cv file */
  const [cv, updateCv, formidableCv] = useForm({
    file: null,
    accepted: false,
    sending: false,
  });
  
  const areTermsAccepted: boolean = formTerms.areTermsAccepted && formTerms.isPolicyAccepted;
  
  const isFileMandatory: boolean = !props.mission.withoutCv && props.mission.cvMandatory;
  
  const isFileSet: boolean = !!cv.accepted;
  
  const isButtonActive: boolean =  isFileMandatory ? (areTermsAccepted && isFileSet && !cv.sending) : (areTermsAccepted && !cv.sending);
  
  /** When CV Updates **/
  useEffect((): any => {
    (() => {
      if(!!cv.file) {
    
        if(cv.file.length > 1) {
          updateCv()({target :{type: "custom", name: "file", value: null}});
          updateCv()({target :{type: "custom", name: "accepted", value: false}});
          return notification(appContextV2.dispatchPanels, "Un seul fichier peut être envoyé à la fois", "error");
        }
    
        if(!formidableCv.isFileMime(cv.file[0], ["pdf", "docx", "doc", "xslx", "xsl", "odt", "jpeg", "png"])) {
          updateCv()({target :{type: "custom", name: "file", value: null}});
          updateCv()({target :{type: "custom", name: "accepted", value: false}});
          return notification(appContextV2.dispatchPanels, "Le type du fichier n'est pas pris en charge", "error");
        }
    
        if(!formidableCv.isFileSmallerThan(cv.file[0], 3_000_000)) {
          updateCv()({target :{type: "custom", name: "file", value: null}});
          updateCv()({target :{type: "custom", name: "accepted", value: false}});
          return notification(appContextV2.dispatchPanels, "Le fichier est trop lourd (maximum 3mo)", "error");
        }
        
        updateCv()({target :{type: "custom", name: "accepted", value: true}});
        
        return notification(appContextV2.dispatchPanels, "Fichier correctement ajouté", "success");
      }
    })();
  }, [cv.file]);
  
  const sendFile = async() => {
    try {
      const dataToSend: any = new FormData();
      dataToSend.append("cv", cv.file[0], cv.file[0].name);
      const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/upload/candidate/cv`, dataToSend, {
        withCredentials: true, timeout: 15000, headers: {
          'accept': 'application/json', 'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data; boundary=${dataToSend._boundary}`,
        }
      });
      if(fetched.data.status !== "ok") {
        return "";
      }
      return fetched.data.data;
    } catch(error) {
      return "";
    }
  }
  
  const nextStep = async() => {
    try {
      updateCv()({target :{type: "custom", name: "sending", value: true}});
      
      let fetchFileUrl = "";
      
      if(isFileSet) {
        fetchFileUrl = await sendFile();
        devLog("fetchFileUrl", fetchFileUrl);
        if(fetchFileUrl === "" && isFileMandatory) {
          return notification(appContextV2.dispatchPanels, "Une erreur est survenue dans l'envoi du CV", "error");
        }
        props.updateAnswer()({target: {type: "custom", name: "cvUrl", value: fetchFileUrl}});
      }
      props.setTimeStart(Math.floor((new Date().getTime())/1000));
      updateCv()({target :{type: "custom", name: "sending", value: false}});
      
      // start the timer
      props.wizard.nextStep();
  
    } catch(error) {
      
      updateCv()({target :{type: "custom", name: "sending", value: false}});
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      devLog(error);
    }
  }
  
  return(<SubPageLayout status={"ok"} subPage={props.subPage}>
    
    <Spaces n={2}/>
    
    <section className={"container"}>
      
      <div className={"apply-V2__title"}>
        Postulez en répondant aux quelques questions du recruteur.
      </div>
      
      <Spaces n={0}/>
      
      <div className={"text--center text--grey text--magnified11x"}>
        Temps indicatif: <span className={"text--magnified11x"}>3 minutes</span>
      </div>
      
      <Spaces n={0}/>
      
      {/*--------------------------------- Description */}
      <article className={"white-container white-container--grey"}>
        
        <div className={"link-box2 link-box2--white link-box2--left animation-fade-in"} >
          <div>Titre du poste: <span className={"text--new-blue"}> {capitalizeSentence(props.mission?.positionWanted)}</span></div>
        </div>
        
        <Spaces n={0}/>
        
        {props.mission?.description && <><div className={"link-box2 link-box2--white link-box2--left animation-fade-in"} >
          <div>Description: <span className={"text--new-blue"}> {props.mission?.description}</span></div>
        </div><Spaces n={0}/></>}
        
        {props.mission?.documentUrl && <><div className={"link-box2 link-box2--white link-box2--left animation-fade-in"} >
          <div>Fiche de poste: <a href={props.mission?.documentUrl}>Lien vers la fiche de poste</a></div>
        </div><Spaces n={0}/></>}
        
        <div className={"link-box2 link-box2--white link-box2--left animation-fade-in"} >
          <div>Contrat: <span className={"text--new-blue"}> {getContract(props.mission?.contract)}</span></div>
        </div>
        
        <Spaces n={0}/>
        
        <div className={"link-box2 link-box2--white link-box2--left animation-fade-in"} >
          <div>Expérience requise pour le poste: <span className={"text--new-blue"}> {getExperience(props.mission?.yearsOfExperience)}</span></div>
        </div>
        
        <Spaces n={0}/>
        
        <div className={"link-box2 link-box2--white link-box2--left animation-fade-in"} >
          <div>Dates: <span className={"text--new-blue"}> {getStarting(props.mission.from, props.mission.asSoonAsPossible, props.mission.duration, props.mission.permanent)}</span></div>
        </div>
        
        <Spaces n={0}/>
        
        <div className={"link-box2 link-box2--white link-box2--left animation-fade-in"} >
          <div>Salaire: <span className={"text--new-blue"}> {getSalary(props.mission.salaryMin, props.mission.salaryMax, props.mission.salaryBy, 'eur')}</span></div>
        </div>
        
        <Spaces n={0}/>
        
        <div className={"link-box2 link-box2--white link-box2--left animation-fade-in"} >
          <div>Lieu: <span className={"text--new-blue"}> {getLocation(props.mission.remote, props.mission.roaming, props.mission.roamingPlace, props.mission.location)}</span></div>
        </div>
  
        <Spaces n={0}/>
  
        {
          !!props.mission.advantages && props.mission.advantages.length !== 0 && <><div className={"link-box2 link-box2--white link-box2--left animation-fade-in"} ><div>
            Avantages:
            <br/>
            {props.mission.advantages?.map((item: any, key: number) => (<div key={key}>- <span className={"text--new-blue"}>{item}</span></div>))}</div>
          </div><Spaces n={0}/></>
        }
  
        {
          !!props.mission.documentUrl && <div className={"link-box2 link-box2--white link-box2--left animation-fade-in"} >
            <div>Fiche de poste: <a className={"modern-link"} href={props.mission.documentUrl}>Télécharger la fiche de poste</a></div>
          </div>
        }
        
      </article>
      
      <Spaces n={0}/>
      
      {/*--------------------------------- CV */}
      <article className={"white-container white-container--grey"}>
        {props.mission.withoutCv && <div className={"text--center apply-V2__title"}>Recrutement sans CV</div>}
        {!props.mission.withoutCv && !props.mission.cvMandatory &&!cv.accepted && <div className={"text--center apply-V2__title"}>CV facultatif</div>}
        {!props.mission.withoutCv && props.mission.cvMandatory &&!cv.accepted && <div className={"text--center apply-V2__title"}>CV nécéssaire pour continuer</div>}
        {!props.mission.withoutCv &&cv.accepted && <div className={"text--center apply-V2__title"}>CV accepté</div>}
        {
          !props.mission.withoutCv && <>
            <br/>
            <div className={"space-flex--center"}>
              <FileDrop square={false} name={"file"} cb={updateCv}/>
            </div>
          </>
        }
      </article>
      
      <Spaces n={0}/>
      
      {
        props.mission?.status === "published" && <>
          <div className={"text--center"}>
            <button className={"btn-main"} disabled={!isButtonActive} onClick={() => nextStep()}>
              Je postule
            </button>
          </div>

          <Spaces n={0}/>
  
          {/*------------------------------------------- Terms & Policy */}
          <div className={"space-flex--center"}>
            <div>
              <input type={"checkbox"} name={"areTermsAccepted"} value={formTerms.areTermsAccepted} onChange={updateTerms()}/> J'accepte les <Link to={"/terms"}><span className={"modern-link"}>conditions générales</span></Link>
              <Spaces n={0}/>
              <input type={"checkbox"} name={"isPolicyAccepted"} value={formTerms.isPolicyAccepted} onChange={updateTerms()}/> J'ai pris connaissance de la <Link to={"/policy"}><span className={"modern-link"}>charte de confidentialité</span></Link>
            </div>
          </div>
        </>
      }
    
    </section>
  
    {cv.sending && <>
      <br/>
      <LoadingStyle2/>
    </>}
  
  </SubPageLayout>);
}