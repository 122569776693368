import AppContext, {IAppV2Context} from "../../../../componentsApp/context/AppContext";
import React, {useContext} from "react";
import WindowSubscribeSetQuality from "./componentsWS/WindowSubscribeSetQuality";
import WindowSubscribeSetStrategy from "./componentsWS/WindowSubscribeSetStrategy";
import WindowSubscribeSetTerms from "./componentsWS/WindowSubscribeSetTerms";
import {withRouter} from 'react-router-dom'
import WindowSubscribeUserExists from "./componentsWS/WindowSubscribeUserExists";
import WindowWrapper from "../../../layouts/WindowLayout";
import {useForm} from "use-formidable";
import {useWizard} from "use-wizard";
import WindowError from "./WindowError";

export default withRouter(WindowSubscribe);

function WindowSubscribe(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const language: string = appContextV2.appSession.app.language;
  
  /** Handles email if one was set when user tried to login with unexisting mail */
  const initialEmail = appContextV2.appPanels.window.windowOptions.data.email ? appContextV2.appPanels.window.windowOptions.data.email : "";
  
  /** Sets wizard */
  // const [step, wizard] = useWizard(["quality", "strategy", "terms", "alreadyExists", "error"]);
  const [step, wizard] = useWizard(["strategy", "terms", "alreadyExists", "error"]);
  
  /** Sets form */
  const [form, updateForm, formHandler] = useForm({
    quality: "recruiter",
    strategy: "email",
    email: initialEmail,
    areTermsAccepted: false,
    isPolicyAccepted: false,
  });
  
  const windowContext: any = {
    form: form,
    updateForm: updateForm,
    formHandler: formHandler,
    step: step,
    wizard: wizard,
  };
  
  switch(step) {
    
    case "quality":
      if(appContextV2.appSession.app.allowSignup) {
        return (<WindowWrapper windowTitle={"Bienvenue sur Myshortlist.co !"} status={"ok"}>
          <WindowSubscribeSetQuality windowContext={windowContext}/>
        </WindowWrapper>);
      } else {
        return (<WindowWrapper windowTitle={"Bienvenue sur Myshortlist.co !"}>
            <span>Il n'est pas possible de créer de compte pour le moment. Contactez Dan à l'adresse <span className={"text--bold"}>dan@black-belt.io</span></span>
        </WindowWrapper>);
      }
      
    case "strategy":
      return (<WindowWrapper windowTitle={"Recrute mieux, plus vite."} status={"ok"}>
        <WindowSubscribeSetStrategy windowContext={windowContext}/>
      </WindowWrapper>);
      
    case "terms":
      return (<WindowWrapper windowTitle={"Recrute mieux, plus vite."} status={"ok"}>
        <WindowSubscribeSetTerms windowContext={windowContext}/>
      </WindowWrapper>);
      
    case "alreadyExists":
      return (<WindowWrapper windowTitle={"Recrute mieux, plus vite."} status={"ok"}>
        <WindowSubscribeUserExists windowContext={windowContext}/>
      </WindowWrapper>);
      
    case "error":
    default :
      return (<WindowError />);
  
  }

}
