import React from "react";
import Spaces from "../../../../Spaces";
import {format} from "date-fns";

export default (props: any) => {
  
  return (<>
  
    <CandidateAnswers answers={props.candidate?.candidacy?.answers} skills={props.candidate?.candidacy?.answers} candidacy={props.candidate?.candidacy} />
  
  </>);
}

function CandidateAnswers(props: {
  answers: any,
  skills: any,
  candidacy: any,
}) {
  
  return (<>
    
    <div className={"results-V2__metadata"}>
      Date de la candidature: {format(new Date(props.candidacy?.dateOfCreation), "dd-MM-yyyy")}
      <br/>
      {props.candidacy?.webAmbassador && <>
        Ambassadeur / source: {props.candidacy?.webAmbassador}
        <br/>
      </>}
      {props.candidacy?.webOrigin && <>
        Source: {props.candidacy?.webOrigin}
        <br/>
      </>}
      
    </div>
    
    <Spaces n={0}/>
    
    <section className={"results-V2"}>
      <article className={"results-V2__item"}>
        <div className={"results-V2__item__title"}>
          Questionnaire
        </div>
        <div className={"results-V2__item__value"}>
          {props.candidacy?.markForm} %
        </div>
        <div className={"results-V2__item__bar"}>
          <div className={`results-V2__item__bar--selected ${getProgressionStyle(props.candidacy?.markForm)}`}>
          </div>
        </div>
      </article>
      <article className={"results-V2__item"}>
        <div className={"results-V2__item__title"}>
          Compétences
        </div>
        <div className={"results-V2__item__value"}>
          {props.candidacy?.markSkills} %
        </div>
        <div className={"results-V2__item__bar"}>
          <div className={`results-V2__item__bar--selected ${getProgressionStyle(props.candidacy?.markSkills)}`}>
          </div>
        </div>
      </article>
      <article className={"results-V2__item"}>
        <div className={"results-V2__item__title"}>
          Réputation
        </div>
        <div className={"results-V2__item__value"}>
          À venir
        </div>
        <div className={"results-V2__item__bar"}>
          <div className={`results-V2__item__bar--selected`}>
          </div>
        </div>
      </article>
    </section>
    
    <Spaces n={2}/>
    <p className={"results-v2__title"}>
      Réponses du candidat
    </p>
  
    <Spaces n={0}/>
    
    <div className={"text--center text--grey text--minified2x"}>
      Une réponse valorisée apparait en vert. Une réponse non valorisée apparait en grise. Une réponse éliminatoire apparait en rouge.
    </div>
    
    <Spaces n={0}/>
    
    {/*{props.answers?.filter((item: any) => (['freeText', 'singleChoice', 'multipleChoice'].includes(item.question?.type))).map((item: any, key: any) => (*/}
    {/*  <article className={`results-v2__answer`}>*/}
    {/*  <p className={"text--minified3x"}>Temps passé sur la question: {item.timeSpent} seconde{item.timeSpent !== 1 ? "s" : ""}</p>*/}
    {/*  <h1 className={"candidate-answers__question"}>{key + 1}. {item.question?.question}</h1>*/}
    {/*  <CandidateAnswer item={item} index={key}/>*/}
    {/*  <Spaces n={1}/>*/}
    {/*</article>))}*/}
  
    {props.answers?.filter((item: any) => (['freeText', 'singleChoice', 'multipleChoice'].includes(item.question?.type))).map((item: any, key: any) => (
      <article key={key} className={`results-v2__answer`}>
        <div className={"text--minified3x"}>Temps passé sur la question: {item.timeSpent} seconde{item.timeSpent !== 1 ? "s" : ""}</div>
        <div className={"results-v2__answer__question"}>({key + 1}) {item.question?.question}</div>
        <CandidateAnswer item={item} index={key}/>
        {/*<Spaces n={1}/>*/}
      </article>))}
    
    <Spaces n={1}/>
    
    {
      props.candidacy?.skills?.length !== 0 && <>
        <p className={"results-v2__title"}>
          Compétences métier
        </p>
        <Spaces n={0}/>
        <div className={"text--center text--grey text--minified2x"}>
          En vert: le candidat a séléctionné cette compétence.
        </div>
        <Spaces n={0}/>
        {props.candidacy?.skills?.map((item: any, key: any) => (<section key={key} className={`results-v2__skill ${item?.selected ? "results-v2__skill--green" : "results-v2__skill--red"}`}>
          {/*<CandidateSkill item={item} index={key}/>*/}
          «{item.skill}»
        </section>))}
      </>
    }
    <Spaces n={1}/>
  </>);
}

function CandidateAnswer(props: any) {
  switch(props.item?.question?.type) {
    case 'freeText':
      return (<p className={"text--center"}>{props.item.answer}</p>);
    case 'singleChoice':
      switch(props.item?.question?.blocking) {
        case 'true':
          return (<>{props.item?.question?.answers?.map((item2: any, key: any) => (<p key={key} className={`candidate-answers__answer
            ${(props.item.answer === key && (item2.answerData === "keep")) ? "candidate-answers__answer--good" : ""}
            ${(props.item.answer === key && (item2.answerData === "block")) ? "candidate-answers__answer--wrong" : ""}`}>
            {item2.answer}
          </p>))}</>);
        case 'false':
          return (<>{props.item?.question?.answers?.map((item2: any, key: any) => (<p key={key} className={`candidate-answers__answer
            ${(props.item.answer === key && (item2.answerData !== "0")) ? "candidate-answers__answer--good" : ""}
            ${(props.item.answer === key && (item2.answerData === "0")) ? "candidate-answers__answer--grey" : ""}`}>
            {item2.answer} (importance: {item2.answerData === "0" ? "nulle":""} {item2.answerData === "2" ? "faible":""} {item2.answerData === "5" ? "forte":""})
          </p>))}</>);
        default:
          return (<>Err</>);
      }
    case 'multipleChoice':
      switch(props.item?.question?.blocking) {
        case 'true':
          return (<>{props.item?.question?.answers?.map((item2: any, key: any) => {
            return <p className={`candidate-answers__answer
            ${(props.item?.answer.includes(key) && (item2.answerData === "keep")) ? "candidate-answers__answer--good" : ""}
            ${(props.item?.answer.includes(key) && (item2.answerData === "block")) ? "candidate-answers__answer--wrong" : ""}
            `}>
              {item2.answer}
            </p>
          })}</>);
        case 'false':
          return (<>{props.item?.question?.answers?.map((item2: any, key: any) => {
            return <p className={`candidate-answers__answer
            ${(props.item?.answer.includes(key) && (item2.answerData !== "0")) ? "candidate-answers__answer--good" : ""}
            ${(props.item?.answer.includes(key) && (item2.answerData === "0")) ? "candidate-answers__answer--grey" : ""}
            `}>
              {item2.answer}
            </p>
          })}</>);
        default:
          return (<>Err</>);
      }
  }
  return (<>
  </>);
}

function CandidateSkill(props: any) {
  const isSkillNeeded: boolean = props.item?.needed;
  const isSkillSelected: boolean = props.item?.selected;
  const shouldSkillBeDisplayed: boolean = isSkillSelected || isSkillNeeded;
  const isSkillGreen: boolean = isSkillNeeded && isSkillSelected;
  const isSkillRed: boolean = isSkillNeeded && !isSkillSelected;
  if(shouldSkillBeDisplayed) {
    return (<p
      className={`candidate-answers__answer ${isSkillGreen ? "candidate-answers__answer--good" : ""} ${isSkillRed ? "candidate-answers__answer--wrong" : ""}`}>
      * {isSkillRed ? "Le candidat n'a pas séléctionné cette compétence: " : ""} "{props.item.skill}"
    </p>);
  } else {
    return (<></>);
  }
}

function getProgressionStyle(mark: number) {
  
  if(!mark) return "";
  
  if(mark === 100) return "results-V2__item__bar--selected--100";
  if(mark >= 90) return "results-V2__item__bar--selected--90";
  if(mark >= 80) return "results-V2__item__bar--selected--80";
  if(mark >= 70) return "results-V2__item__bar--selected--70";
  if(mark >= 60) return "results-V2__item__bar--selected--60";
  if(mark >= 50) return "results-V2__item__bar--selected--50";
  if(mark >= 40) return "results-V2__item__bar--selected--40";
  if(mark >= 30) return "results-V2__item__bar--selected--30";
  if(mark >= 20) return "results-V2__item__bar--selected--20";
  if(mark >= 10) return "results-V2__item__bar--selected--10";
  
  return "";
}