import React, {useContext, useEffect} from "react";
import AppContext, {IAppV2Context} from "../componentsApp/context/AppContext";
import LandingPageNavBarV2 from "./componentsLandingPageV2/LandingPageNavBarV2";
import SplashScreenV2 from "./componentsLandingPageV2/SplashScreenV2";
import LP2Kanban from "./componentsLandingPageV2/LP2Kanban";
import Spaces from "../sharedComponents/components/Spaces";
import LP2Form from "./componentsLandingPageV2/LP2Form";
import LP2Teams from "./componentsLandingPageV2/LP2Teams";
import LP2BusinessPage from "./componentsLandingPageV2/LP2BusinessPage";

export default function LandingPage(props: { page: string, }) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  useEffect(() => {
    appContextV2.dispatchNavigation({type: "SET_PAGE", value: props.page});
  }, []);
  
  return(<>
  
    <LandingPageNavBarV2 />
    
    <SplashScreenV2 />
  
    <Spaces n={0}/>
    
    <LP2Kanban />
    
    <Spaces n={0}/>
  
    <LP2Form/>
  
    <Spaces n={0}/>
  
    <LP2BusinessPage />
  
    <Spaces n={0}/>
  
    <LP2Teams />
    
    <Spaces n={2}/>
  
  </>);
  
}