import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../componentsApp/context/AppContext";

export {RecruiterMissionsFilters};

const RecruiterMissionsFilters = (props: {
  createdBy: string,
  title: string,
  olderThan: number | string,
  status: string,
  earlierThan: number | string,
  showHistory: any,
  listOfTrigrams: string[],
  page: number,
  setShowHistory: Function,
  setCreatedBy: Function,
  setStatus: Function,
  setTitle: Function,
  setPage: Function,
  setOlderThan: Function,
  setEarlierThan: Function,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<section className={"filters"}>
    
    <article className={"filters__element"}>
      <div className={"filters__element__title"}>
        Titre du poste
      </div>
      <div className={"filters__element__content"}>
        <input type={"text"} className={"filters__element__input"} value={props.title} name={"name"} onChange={(e) => {
          props.setTitle(e.target.value);
          if(props.page !== 1) props.setPage(1);
        }}/>
      </div>
    </article>
    
    <article className={"filters__element"}>
      <div className={"filters__element__title"} title={"Dernière action depuis moins de ... (en jours)"}>
        Crée avant (jours)
      </div>
      <div className={"filters__element__content"}>
        <input type={"number"} className={"filters__element__input"} value={props.olderThan} name={"before"}
               onChange={(e) => {
                 props.setOlderThan(e.target.value);
                 if(props.page !== 1) props.setPage(1);
               }}/>
      </div>
    </article>
    
    <article className={"filters__element"}>
      <div className={"filters__element__title"} title={"Dernière action depuis plus de ... (en jours)"}>
        Crée depuis (jours)
      </div>
      <div className={"filters__element__content"}>
        <input type={"number"} className={"filters__element__input"} name={"after"} value={props.earlierThan}
               onChange={(e) => {
                 props.setEarlierThan(e.target.value);
                 if(props.page !== 1) props.setPage(1);
               }}/>
      </div>
    </article>
  
    <article className={"filters__element"}>
      <div className={"filters__element__title"}>
        Créé par
      </div>
      <div className={"filters__element__content"}>
        <select className={"filters__select"} value={props.createdBy} onChange={(e) => {
          props.setCreatedBy(e.target.value);
          if(props.page !== 1) props.setPage(1);
        }}>
          <option value={""}>Tous</option>
          {props.listOfTrigrams?.map((item: string, key: number) => <option key={key} value={item}>{item}</option>)}
        </select>
      </div>
    </article>
  
    <article className={"filters__element"}>
      <div className={"filters__element__title"} title={"Dernière action depuis plus de ... (en jours)"}>
        Statut
      </div>
      <div className={"filters__element__content"}>
        <select className={"filters__select"} value={props.status} onChange={(e) => {
          props.setStatus(e.target.value);
          if(props.page !== 1) props.setPage(1);
        }}>
          <option value={""}>Tous</option>
          <option value={"published"}>Publiés</option>
          <option value={"collaboration"}>Collaboration</option>
          <option value={"draft"}>Brouillon</option>
        </select>
      </div>
    </article>
  
    <article className={"filters__element"}>
      <div className={"filters__element__title"} title={"Voirs les postes archivés"}>
        Archives
      </div>
      <div className={"filters__element__content filters__element--center"}>
        <label className={"switch-V2 space-relative-bottom"}>
          <input type={"checkbox"} className={"checkbox-on-off"} value={props.showHistory} onChange={() => {
            props.setShowHistory((ps: boolean) => !ps);
            props.setStatus("");
            if(props.page !== 1) props.setPage(1);
          }} />
          <span className="slider"> </span>
        </label>
      </div>
    </article>
    
    {/*<article title={appContextV2.appSession.session.userRole === "admin" ? "" : "Seuls les admin peuvent consulter les communautés d'autres équipes"}*/}
    {/*         className={`filters__element ${appContextV2.appSession.session.userRole === "admin" ? "" : "filters__element--disabled"}`}>*/}
    {/*  <div className={"filters__element__title"}>*/}
    {/*    Équipe*/}
    {/*  </div>*/}
    {/*  <div className={"filters__element__content"}>*/}
    
    {/*  </div>*/}
    {/*</article>*/}
  
  </section>);
}
